import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { getAuthToken } from "./components/Auth";
import Dashboard from './components/Dashboard';
import PushNotification from './components/PushNotification';
import Settings from './components/Settings';
import { AuthProvider } from './components/AuthContext';
import NewSignUp from './components/NewSignUp';
import NewSignIn from './components/NewSignIn';
import Plan from './components/Plan';
import Platforms from './components/Platforms';
import OnboardSteps from './components/OnboardSteps';
import Subscriptions from './components/Subscriptions';
import Applications from './components/Applications';
import Organizations from './components/Organizations';
import NewApp from './components/NewApp';
import AppSetting from './components/AppSetting';
import TestModal from './components/TestModal';
import TestSubscription from './components/TestSubscription';
import PushSetting from './components/PushSetting';

const App = () => {
  const [token, setToken] = useState(null);
  const [onboard, setOnboard] = useState(false);
  const [dashboardClass,setDashboardClass] = useState("dashboard_page")
  const [currentSidebar, setCurrentSidebar] = useState('defaultSidebar');
  const [ appId,setAppId]=useState(0)
  const [thirdSidebar,setThirdSidebar] =  useState(0);
  // Function to handle setting the token
  function handleSaveToken(token, userData) {
    setToken(token);
    localStorage.setItem('token', token);  // Save token in localStorage (for persistence after page reload)

    localStorage.setItem('userData', userData);
  }


  const handleSetOnboard = () => {
    setOnboard(true);
    localStorage.setItem('onboard', true);  // Save onboard status in localStorage
  };

  // Function to handle removing the token (on sign out)
  const handleRemoveToken = () => {
    setToken(null);
    setOnboard(false);
    localStorage.removeItem('application');
    localStorage.removeItem('token');  // Remove token from localStorage
    localStorage.removeItem('userData');
    localStorage.removeItem('onboard');
  };

  // Load token and onboarding status from localStorage (for session persistence)
  useEffect(() => {
    const savedToken = getAuthToken();
    const savedOnboard = localStorage.getItem('onboard') === 'true';

    
    
    if (savedToken) {
      setToken(savedToken);
    }
    if (savedOnboard) {
      setOnboard(true);
    }
    let app = localStorage.getItem("activeApp")
    
    if (app) {
      setCurrentSidebar("dashboardSidebar")
      setAppId(app)
    }
  }, []);


  // Menu items and corresponding components
  const menuItems = [
    { name: 'Dashboard', route: 'dashboard', component: Dashboard },
    { name: 'Push Notification', route: 'apps/:id/push-notification', component: PushNotification },
    { name: 'Platforms', route: '/apps/:appId/platform', component: Platforms },
    { name: 'Subscriptions', route: 'apps/:appId/subscriptions', component: Subscriptions },
    { name: 'TestSubscription', route: 'apps/:appId/testsubscription', component: TestSubscription },
    { name: 'Settings', route: 'settings', component: Settings },
  ];

  function handleDashboardClass(expand) {
    console.log("expand ",expand);
    if (expand) {
      setDashboardClass("dashboard_page collapsed_menu")
    }else{
      setDashboardClass("dashboard_page")
    }
    
  }

    // Function to change sidebar state and store it in localStorage
    const handleChangeSidebar = (newSidebar,thirdSidebar) => {
      console.log(newSidebar,thirdSidebar);
      setThirdSidebar(thirdSidebar)
      setCurrentSidebar(newSidebar);
      localStorage.setItem('currentSidebar', newSidebar); // Store sidebar state
      localStorage.setItem('thirdSidebar', thirdSidebar); 
    };

    console.log(currentSidebar);
    

    function handleSetAppId(id) {
      localStorage.setItem("activeApp",id)
      setAppId(id)
    }





  return (
      <AuthProvider>
        <div  >
          {token && onboard ? (
            // If authenticated, show Sidebar, Header, and protected routes
          

           
            <div className={dashboardClass}>
              <Sidebar appId={appId}  menuItems={menuItems} handleRemoveToken={handleRemoveToken} handleDashboardClass={handleDashboardClass} type={currentSidebar} thirdSidebarState={thirdSidebar} />
              
              {/* <div className="flex-grow-1" style={{ marginLeft: "14%", backgroundColor: "#f3f3f3" }}> */}
                {/* <Header handleRemoveToken={handleRemoveToken} /> */}
                <div style={{ margin: "1.8%" }}>
                  <Routes>
                    {/* {menuItems.map((item) => (
                      <Route key={item.route} path={`/${item.route}`} element={<item.component />} />
                    ))} */}
                    <Route path="/" element={<Navigate to="/apps" />} />
                     <Route  path={`/apps`} element={<Applications changeSidebar={handleChangeSidebar}  />} />
                     <Route  path={`/apps/:id`} element={<Dashboard  handleSetAppId={handleSetAppId}/>} />
                     <Route  path={'/testmodal'} element={<TestModal/>}/>
                     <Route  path={`/organizations`} element={<Organizations />} />
                     <Route  path={`/apps/:id/push-notification`} element={<PushNotification />} />
                     <Route  path={`/apps/:appId/platform`} element={<Platforms  changeSidebar={handleChangeSidebar}/>} />
                     <Route  path={`/apps/:appId/subscriptions`} element={<Subscriptions />} />
                     <Route  path={`/apps/:appId/testsubscription`} element={<TestSubscription />} />
                     <Route  path={`/apps/new/:id/setting/configure/:channel_type`} element={<AppSetting currentSidebar={currentSidebar} changeSidebar={handleChangeSidebar} />}  />
                     <Route  path={`/apps/new`} element={<NewApp changeSidebar={handleChangeSidebar} />} />
                     <Route  path={`/apps/:id/settings/messaging`} element={<PushSetting />} />
                  </Routes>
                </div>
              {/* </div> */}
                    </div>
          ) : token && !onboard ? (<Routes>
              <Route path="/" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard}/>} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/steps" element={<OnboardSteps handleSetOnboard={handleSetOnboard} />} />
            <Route path="/signIn" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard} />} />
            <Route path="/signup" element={<NewSignUp handleSaveToken={handleSaveToken} />} />
            {/* <Route path="*" element={<Navigate to="/plan" />} /> */}
          </Routes>) : (
            // If not authenticated, show SignIn or SignUp
            <Routes>
              <Route path="/" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard} />} />
              <Route path="/signIn" element={<NewSignIn handleSaveToken={handleSaveToken} handleSetOnboard={handleSetOnboard} />} />
              <Route path="/signup" element={<NewSignUp handleSaveToken={handleSaveToken} />} />
              
              {/* Redirect all other routes to SignIn if unauthenticated */}
              {/* <Route path="*" element={<Navigate to="/signIn" />} /> */}
            </Routes>
          )}
        </div>
      </AuthProvider>
  );
};


// <Routes>
// { token && !isPlan ? <Route path="/plan" element={<Plan handleSetOnboard={handleSetOnboard} />} />:""}
// </Routes>
export default App;
