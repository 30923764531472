import React, { useState, useEffect } from 'react';
import { Utils } from '../index';
import { useNavigate } from "react-router";
import web from '../assets/icons/webimg.png';

const OnboardSteps = ({ handleSetOnboard }) => {

    const navigate = useNavigate()
    let userData = JSON.parse(localStorage.getItem('userData'));
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [category, setCategory] = useState("")
    const [appName, setAppName] = useState("")
    const [wantToUse, setWantToUse] = useState([])
    const [channel, setChannel] = useState([])
    const [phone, setPhone] = useState(0)
    const [orgId, setOrgId] = useState(0)
    const [channelList, setChannelList] = useState([])
    const [wantToUseList, setWantToUseList] = useState([])
    const [firstStep, setFirstStep] = useState(false)
    const [firstNameAlert, setFirstNameAlert] = useState(false)
    const [lastNameAlert, setLastNameAlert] = useState(false)
    const [categoryAlert, setCategoryAlert] = useState(false)
    const [wantToUseAlert, setWantToUseAlert] = useState(false)
    const [channelAlert, setChannelAlert] = useState(false)
    const [firstStepError, setFirstStepError] = useState(false)

    const [secondStep, setSecondStep] = useState(false)
    const [orgName, setOrgName] = useState("")
    const [website, setWebsite] = useState("")
    const [notWebsite, setNotWebsite] = useState(false)
    const [industry, setIndustry] = useState("")
    const [companySize, setCompanySize] = useState("")
    const [orgNameAlert, setOrgNameAlert] = useState(false)
    const [websiteAlert, setWebsiteAlert] = useState(false)
    const [industryAlert, setIndustryAlert] = useState(false)
    const [companySizeAlert, setCompanySizeAlert] = useState(false)
    const [secondStepError, setSecondStepError] = useState(false)
    const [appNameAlert, setAppNameAlert] = useState(false)

    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };


    useEffect(() => {

        fetch(Utils.baseurl + 'user/getbyid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userData.id }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.status) {

                    if (result.data.onboard_first) {
                        setFirstStep(true)
                    }

                    if (result.data.onboard_second) {
                        setSecondStep(true)
                    }
                    if (result.data.organizations && result.data.organizations.length) {
                        setOrgId(result.data.organizations[0].id)
                    }
                    // handleSetOnboard()
                    // navigate(`/push-notification`);
                } else {
                    alert(result.message)
                }
            })
            .catch((error) => console.error(error));




        fetch(Utils.baseurl + "want-to-use/list", requestOptions)
            .then((response) => response.json()) // Parse response as JSON
            .then((result) => {
                setWantToUseList(result.data)

            })
            .catch((error) => console.error(error));


        fetch(Utils.baseurl + "channel/list", requestOptions)
            .then((response) => response.json()) // Parse response as JSON
            .then((result) => {
                setChannelList(result.data)

            })
            .catch((error) => console.error(error));

    }, []);

    function handleSaveApp() {

        if (appName.length && channel.length) {
            let data = {
                onboard: true,
                orgId: orgId,
                application_name: appName,
                userId: userData.id,
                channel:channel
            }
            fetch(Utils.baseurl + 'save/onboard-third', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        console.log(result);
                        localStorage.setItem('application', JSON.stringify([result.data]))
                       let channel_type
                        if (channel == "apple-ios") {
                            channel_type = 1
                        }
                        if (channel == "google-android") {
                            channel_type = 2
                        }
                        if (channel == "web") {
                            channel_type = 3
                        }
                        handleSetOnboard()
                        console.log(channel_type);
                        console.log(result.data.app_id);
                        
                        
                        navigate(`/apps/new/${result.data.app_id}/setting/configure/${channel_type}`);
                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));
        } else {
            if (!appName.length) {
                setAppNameAlert(true)
            }
            if (!channel.length) {
                setChannelAlert(true)
            }
        }

    }


    function handleSaveFirstStep() {

        if (!firstName.length) {
            setFirstNameAlert(true)
        }
        if (!lastName.length) {
            setLastNameAlert(true)
        }
        if (!category.length) {
            setCategoryAlert(true)
        }
        // if (!channel.length) {
        //     setChannelAlert(true)
        // }
        // if (!wantToUse.length) {
        //     setWantToUseAlert(true)
        // }

        if (!firstStepError) {

            let firstStep = {}

            firstStep.firstName = firstName
            firstStep.lastName = lastName
            firstStep.category = category
            firstStep.wantToUse = "wantToUse"
            firstStep.channel = "channel"
            firstStep.phone = phone
            firstStep.userId = userData.id
            fetch(Utils.baseurl + 'save/onboard-first', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(firstStep),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        setFirstStep(true)
                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));


        }

    }
console.log(channel);

    useEffect(() => {
        if (appName.length) {
            setAppNameAlert(false)
        }
        if (channel.length) {
            setChannelAlert(false)
        }
        if (firstName.length) {
            setFirstNameAlert(false)
            setFirstStepError(false)
        } else {
            setFirstStepError(true)
        }

        if (lastName.length) {
            setLastNameAlert(false)
            setFirstStepError(false)
        } else {
            setFirstStepError(true)
        }
        if (category.length) {
            setCategoryAlert(false)
            setFirstStepError(false)
        } else {
            setFirstStepError(true)
        }
        // if (wantToUse.length) {
        //     setWantToUseAlert(false)
        //     setFirstStepError(false)
        // } else {
        //     setFirstStepError(true)
        // }
        // if (channel.length) {
        //     setChannelAlert(false)
        //     setFirstStepError(false)
        // } else {
        //     setFirstStepError(true)
        // }
    }, [firstName, lastName, category, channel, wantToUse, appName,channel]);




    function handleSaveSecondStep() {

        if (!orgName.length) {
            setOrgNameAlert(true)
        }
        if (!website.length && !notWebsite) {
            setWebsiteAlert(true)
        }
        if (!industry.length) {
            setIndustryAlert(true)
        }
        if (!companySize.length) {
            setCompanySizeAlert(true)
        }

        if (!secondStepError) {
            let secondStep = {}
            secondStep.orgName = orgName
            secondStep.website = website
            secondStep.isWebsite = !notWebsite
            secondStep.industry = industry
            secondStep.companySize = companySize
            secondStep.userId = userData.id

            fetch(Utils.baseurl + 'save/onboard-second', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(secondStep),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        setOrgId(result.orgId)
                        setSecondStep(true)
                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));
        }

    }

    useEffect(() => {

        if (orgName.length) {
            setOrgNameAlert(false)
            setSecondStepError(false)
        } else {
            setSecondStepError(true)
        }

        if (website.length || notWebsite) {
            setWebsiteAlert(false)
            setSecondStepError(false)
        } else {
            setSecondStepError(true)
        }
        if (industry.length) {
            setIndustryAlert(false)
            setSecondStepError(false)
        } else {
            setSecondStepError(true)
        }
        if (companySize.length) {
            setCompanySizeAlert(false)
            setSecondStepError(false)
        } else {
            setSecondStepError(true)
        }
    }, [orgName, website, notWebsite, industry, companySize]);





    return (
        <>
            {
                firstStep && secondStep ?
                    <div className="steps_page">
                        <div className="steps_outer">
                            <div className="steps_inner">
                                <div className="steps_heading">
                                    <h1 color="black">🎉 Welcome to NotifySphere!</h1>
                                </div>
                                <div className="steps_form_outer step_2">
                                    <div className="steps_left">
                                        <div className="inner_heading">
                                            <h2 color="black">Now add your first app or website</h2>
                                        </div>
                                        <div className="steps_sub_heading">
                                            Need help? <a href="#">Read our Quickstart Guide.</a>
                                        </div>
                                        <div className="steps_form">
                                            <div className="name_field">
                                                <div className="field_name">
                                                    <label htmlFor="first-name">Name of your app/website</label>
                                                    <div className="input_field">
                                                        <input
                                                            id="website_name"
                                                            type="text"
                                                            // className="phone_input"
                                                            placeholder="App Name"
                                                            value={appName}
                                                            onChange={(e) => setAppName(e.target.value)}
                                                        />
                                                    </div>

                                                    {appNameAlert ? <div aria-describedby="first-name" className="validation_error">
                                                        <span>Please enter a name for your app</span>
                                                    </div> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="app_field_inner">
                                    <label>Set up your first channel</label>
                                    <div className="select_app">
                                    <ul>
                                        <li>
                                            <input type="radio" name="radio" value={channel}  onChange={() => setChannel("apple-ios")} />
                                            <div className="select_inner">
                                            <svg  style={{ height: "40px", width: "40%" }} viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg>

                                                <span style={{marginTop:"8px"}}>Apple iOS (FCM)</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" name="radio" value={channel}  onChange={() => setChannel("google-android")}/>
                                            <div className="select_inner">
                                            <svg  style={{ height: "40px", width: "40%" }} viewBox="0 0 1274.37 718" fill="currentColor" className="sc-jSMfEi hKsxtk"><path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84"></path></svg>

                                                <span style={{textAlign:"center"}}>Google Android <br /> (FCM)</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" name="radio" value={channel}  onChange={() => setChannel("web")} />
                                            <div className="select_inner">
                                                <img src={web} width="55px" alt="" />
                                                <span style={{marginTop:"5px"}}>Web</span>
                                            </div>
                                        </li>

                                    </ul>

                                        {channelAlert ? <div className="validation_error">
                                            <span>
                                                <img src="error_icon.svg" alt="" />
                                                Please enter a response</span>
                                        </div> : ""}
                                    </div>
                                </div>
                                        <button className="form_btn" onClick={handleSaveApp} style={{ backgroundColor: "rgb(67, 70, 206)" }}>Continue</button>

                                    </div>
                                    <div className="steps_right">
                                        <div className="step_right_heading">2/3 Steps Completed</div>
                                        <div className="step_progress">
                                            <div className="progress_bar">
                                            </div>
                                        </div>
                                        <div className="step_listing">
                                            <div className="step_list filled">
                                                <div className="count_circle">
                                                    <div className="step_count">1</div>
                                                    <div className="step_seperator"></div>
                                                </div>
                                                <label>About You</label>
                                            </div>
                                            <div className="step_list filled">
                                                <div className="count_circle">
                                                    <div className="step_count">2</div>
                                                    <div className="step_seperator"></div>
                                                </div>
                                                <label>Create Organization</label>
                                            </div>
                                            <div className="step_list active">
                                                <div className="count_circle">
                                                    <div className="step_count">3</div>
                                                </div>
                                                <label>Create App</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              

                            </div>
                        </div>
                    </div> :
                    firstStep ? <div className="steps_page">
                        <div className="steps_outer">
                            <div className="steps_inner">
                                <div className="steps_heading">
                                    <h1 color="black">🎉 Welcome to NotifySphere!</h1>
                                </div>
                                <div className="steps_form_outer step_1">
                                    <div className="steps_left">
                                        <div className="inner_heading">
                                            <h2 color="black">Create your first Organization</h2>
                                        </div>
                                        <div className="steps_sub_heading">
                                            An organization is a group of 1 or more apps, e.g. a sandbox app and a production app.
                                        </div>
                                        <div className="steps_form">
                                            <div className="name_field1">
                                                <div className="field_name">
                                                    <label htmlFor="orgname">Company or Organization Name</label>
                                                    <div className="input_field">
                                                        <input id="orgname" value={orgName} placeholder='org name' onChange={(e) => setOrgName(e.target.value)} autoComplete='new-password' />
                                                    </div>

                                                    {orgNameAlert ? <div className="validation_error">
                                                        <span>Please enter a response</span>
                                                    </div> : ""}

                                                </div>
                                            </div>
                                            <div className="name_field1">
                                                <div className="field_name">
                                                    <label htmlFor="website">Website</label>
                                                    <div className="input_field">
                                                        <input id="website" placeholder='site.com or www.site.com' onChange={(e) => setWebsite(e.target.value)} />
                                                    </div>


                                                </div>
                                            </div>


                                            <div className="select_apply_section">
                                                <div className="select_boxes">
                                                    <div className="select_box">
                                                        <input id="I don't have a website" type="checkbox" onChange={(e) => setNotWebsite(e.target.checked)} />
                                                        <label htmlFor="I don't have a website">
                                                            <span>I don't have a website</span>
                                                        </label>
                                                        {websiteAlert ? <div aria-describedby="wbsite" className="validation_error">
                                                            <span>Please enter a response</span>
                                                        </div> : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="category_field">
                                                <label htmlFor="company-role">What industry best describes your company?</label>
                                                <div className="select_field">
                                                    <select className="" name="" onChange={(e) => setIndustry(e.target.value)}>
                                                        <option value="">Select...</option>
                                                        <option value="B2B / Technology">B2B / Technology</option>
                                                        <option value="Ecommerce / Retail">Ecommerce / Retail</option>
                                                        <option value="Education">Education</option>
                                                        <option value="Entertainment">Entertainment</option>
                                                        <option value="Food & Beverage">Food & Beverage</option>
                                                        <option value="Gaming">Gaming</option>
                                                        <option value="Media / News">Media / News</option>
                                                        <option value="Entertainment">Entertainment</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>

                                                {industryAlert ? <div className="validation_error">
                                                    <span>Please enter a response</span>
                                                </div> : ""}

                                            </div>
                                            <div className="category_field">
                                                <label htmlFor="company-size">Company Size</label>
                                                <div className="select_field">
                                                    <select className="" name="" onChange={(e) => setCompanySize(e.target.value)}>
                                                        <option value="">Select...</option>
                                                        <option value="1 - 9">1 - 9 employees</option>
                                                        <option value="10 - 25">10 - 25 employees</option>
                                                        <option value="26 - 100">26 - 100 employees</option>
                                                        <option value="101 - 250">101 - 250 employees</option>
                                                        <option value="250+">250+ employees</option>
                                                    </select>
                                                </div>
                                                {companySizeAlert ? <div aria-describedby="last-name" className="validation_error">
                                                    <span>Please enter a response</span>
                                                </div> : ""}
                                            </div>
                                        </div>
                                        <button className="form_btn" onClick={handleSaveSecondStep} >Continue</button>
                                    </div>
                                    <div className="steps_right">
                                        <div className="step_right_heading">1/3 Steps Completed</div>
                                        <div className="step_progress">
                                            <div className="progress_bar">
                                            </div>
                                        </div>
                                        <div className="step_listing">
                                            <div className="step_list filled">
                                                <div className="count_circle">
                                                    <div className="step_count">1</div>
                                                    <div className="step_seperator"></div>
                                                </div>
                                                <label>About You</label>
                                            </div>
                                            <div className="step_list active">
                                                <div className="count_circle">
                                                    <div className="step_count">2</div>
                                                    <div className="step_seperator"></div>
                                                </div>
                                                <label>Create Organization</label>
                                            </div>
                                            <div className="step_list">
                                                <div className="count_circle">
                                                    <div className="step_count">3</div>
                                                </div>
                                                <label>Create App</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <div className="steps_page">
                            <div className="steps_outer">
                                <div className="steps_inner">
                                    <div className="steps_heading">
                                        <h1 color="black">🎉 Welcome to NotifySphere!</h1>
                                    </div>
                                    <div className="steps_form_outer">
                                        <div className="steps_left">
                                            <div className="inner_heading">
                                                <h2 color="black">Tell us about you</h2>
                                            </div>
                                            <div className="steps_sub_heading">
                                                Please answer a few short questions so we can customize your NotifySphere experience.
                                            </div>
                                            <div className="steps_form">
                                                <div className="name_field">
                                                    <div className="field_name">
                                                        <label htmlFor="first-name">First Name</label>
                                                        <div className="input_field">
                                                            <input id="first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                                        </div>
                                                        {firstNameAlert ? <div aria-describedby="first-name" className="validation_error">
                                                            <span>

                                                                Please enter a response</span>
                                                        </div> : ""}

                                                    </div>
                                                    <div className="field_name">
                                                        <label htmlFor="last-name">Last Name</label>
                                                        <div className="input_field">
                                                            <input id="last_name" onChange={(e) => setLastName(e.target.value)} />
                                                        </div>
                                                        {
                                                            lastNameAlert ? <div aria-describedby="last-name" className="validation_error">
                                                                <span>Please enter a response</span>
                                                            </div> : ""
                                                        }

                                                    </div>
                                                </div>
                                                <div className="category_field">
                                                    <label htmlFor="company-role">Which category best describes your role?</label>
                                                    <div className="select_field">
                                                        <select onChange={(e) => setCategory(e.target.value)} className="" name="Select...">
                                                            <option value="">Select...</option>
                                                            <option value="C-Suite">C-Suite</option>
                                                            <option value="Developer/Engineering">Developer/Engineering</option>
                                                            <option value="Marketing">Marketing</option>
                                                            <option value="Owner / Sole Proprietor">Owner / Sole Proprietor</option>
                                                            <option value="Product">Product</option>
                                                            <option value="Sales">Sales</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                    {
                                                        categoryAlert ? <div aria-describedby="last-name" className="validation_error">
                                                            <span>Please enter a response</span>
                                                        </div> : ""
                                                    }

                                                </div>
                                                {/* <div className="select_apply_section">
                                                    <div className="select_section_label">
                                                        <label htmlFor="">What do you primarily want to use NotifySphere for? (Select all that apply)</label>
                                                    </div>
                                                    <div className="select_boxes">

                                                        {
                                                            wantToUseList.map(item => (

                                                                <div className="select_box">
                                                                    <input id={"wanttouse" + item.id} type="checkbox" className="checkbox" onChange={(e) => e.target.checked ? setWantToUse([...wantToUse, item.id]) : setWantToUse([...wantToUse.filter(data => data !== item.id)])} />
                                                                    <label key={"wanttouse" + item.id} htmlFor={"wanttouse" + item.id}>
                                                                        <span>{item.title}</span>
                                                                    </label>
                                                                </div>

                                                            ))
                                                        }
                                                        {wantToUseAlert ? <div className="validation_error">
                                                            <span>Please enter a response</span>
                                                        </div> : ""}

                                                    </div>
                                                </div>
                                                <div className="select_apply_section">
                                                    <div className="select_section_label">
                                                        <label htmlFor="">What Channels do you plan to use NotifySphere for? (Select all that apply)</label>
                                                    </div>
                                                    <div className="select_boxes">
                                                        {
                                                            channelList.map(item => (

                                                                <div className="select_box">
                                                                    <input id={"channel" + item.id} type="checkbox" className="checkbox" onChange={(e) => e.target.checked ? setChannel([...channel, item.id]) : setChannel([...channel.filter(data => data !== item.id)])} />
                                                                    <label key={"channel" + item.id} htmlFor={"channel" + item.id}>
                                                                        <span>{item.title}</span>
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                        {channelAlert ? <div className="validation_error">
                                                            <span>Please enter a response</span>
                                                        </div> : ""}
                                                    </div>
                                                </div> */}
                                                <div className="phone_section">
                                                    <label htmlFor="phone-number">Phone Number (optional)</label>
                                                    <div className="phone_input">
                                                        <input id="phone-number" maxlength="30" onChange={(e) => setPhone(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={handleSaveFirstStep} className="form_btn">Continue</button>
                                        </div>
                                        <div className="steps_right">
                                            <div className="step_right_heading">0/3 Steps Completed</div>
                                            <div className="step_progress">
                                                <div className="progress_bar">
                                                </div>
                                            </div>
                                            <div className="step_listing">
                                                <div className="step_list active">
                                                    <div className="count_circle">
                                                        <div className="step_count">1</div>
                                                        <div className="step_seperator"></div>
                                                    </div>
                                                    <label>About You</label>
                                                </div>
                                                <div className="step_list">
                                                    <div className="count_circle">
                                                        <div className="step_count">2</div>
                                                        <div className="step_seperator"></div>
                                                    </div>
                                                    <label>Create Organization</label>
                                                </div>
                                                <div className="step_list">
                                                    <div className="count_circle">
                                                        <div className="step_count">3</div>
                                                    </div>
                                                    <label>Create App</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

        </>



    );
};

export default OnboardSteps;
