import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Modal, Table, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import { RxCross1 } from "react-icons/rx";
import { HiPlusCircle } from "react-icons/hi";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast, Slide } from 'react-toastify';
import bellIcon from '../assets/icons/bellIcon.png'
import { Utils } from '../index';
const PushNotification = () => {
    const [priority, setPriority] = useState('Normal');
    const [actionIndex, setActionIndex] = useState(1);
    const [timeToLive, setTimeToLive] = useState(3);
    const [timeUnit, setTimeUnit] = useState('days');
    const [keyValuePairs, setKeyValuePairs] = useState([{ key: '', value: '' }]);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [displaymessage, setdisplayMessage] = useState('Default message');
    const [image, setImage] = useState(null);
    const [largeIcon, setLargeIcon] = useState('');
    const [smallIcon, setSmallIcon] = useState('');
    // const [loader, setLoader] = useState(false);
    const [sound, setSound] = useState('');
    const [lockscreenVisibility, setLockscreenVisibility] = useState('Public');
    const [appData, setAppData] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [isValidImageUrl, setIsValidImageUrl] = useState(false);
    const [actionButtons, setActionButtons] = useState([{ id: '', label: '', iconPath: '', isSystemIcon: false }]);
    const [appUsers,setAppUsers] = useState([])
    const handleClose = () => setShowModal(false);
    // const handleShow = () => setShowModal(true);

    const { id } = useParams()


    const handleAddPair = () => {
        setKeyValuePairs([...keyValuePairs, { key: '', value: '' }]);
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);

    const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);


    const handleCheckboxChange = (subscriptionId) => {
        
        setSelectedSubscriptions(prevState =>
            prevState.includes(subscriptionId)
                ? prevState.filter(id => id !== subscriptionId)
                : [...prevState, subscriptionId]
        );
    };

    function getAppUser() {

        fetch(Utils.baseurl + 'get/app/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then(async (result) => {

                if (result.status) {
                    console.log(result.data);
                    let filterdData = result.data.appUsersData.filter(device => device.isTest === 1)
                    setAppUsers(filterdData)

                }

            })
            .catch((error) => console.error(error));
    }

    useEffect(() => {
        // /getbyid/application
        getAppUser()
        fetch(Utils.baseurl + 'getbyid/application', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.status) {
                    console.log(result.data);
                    setAppData(result.data)
                } else {
                    alert(result.message)
                }
            })
            .catch((error) => console.error(error));




    }, []);




    const handleSendTestPush = () => {
        // setLoader(true)
        let data = formData
        data.subscriptionIds = selectedSubscriptions
        data.image = isValidImageUrl ? imageUrl : null
        data.appId = id
        // setFormData(data)
        fetch(Utils.baseurl + 'send/notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json()) // Parse response as JSON
            .then((result) => {
                toast('Notification Sent Successfully');

            })
            .catch((error) => console.error(error));
        // Add your logic for sending test push here
    };

    const filteredSubscriptions = subscriptions.filter(subscription =>
        subscription.name ? subscription.name.toLowerCase().includes(searchTerm.toLowerCase()) : ""
    );
    const handleRemovePair = (index) => {
        // if (index === 0) return;
        const updatedPairs = keyValuePairs.filter((_, i) => i !== index);
        setKeyValuePairs(updatedPairs);
    };

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPairs = keyValuePairs.map((pair, i) =>
            i === index ? { ...pair, [name]: value } : pair
        );
        setKeyValuePairs(updatedPairs);
    };
    const requestOptions = {
        method: "GET",
        redirect: "follow"
    };


    useEffect(() => {
        console.log("local >> ", localStorage.getItem('userData'));

        fetch(Utils.baseurl + "users", requestOptions)
            .then((response) => response.json()) // Parse response as JSON
            .then((result) => {
                setSubscriptions(result.data)

            })
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        if (message.length == 0) {
            setdisplayMessage("Default message")
        } else {
            setdisplayMessage(message)
        }
    }, [message]);


    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            title,
            message,
            image,
            largeIcon,
            smallIcon,
            sound,
            lockscreenVisibility,
            priority,
            timeToLive,
            timeUnit,
            keyValuePairs,
            actionButtons
        };
        setFormData(data)

        setShowModal(true);
    };

    // Regex to match common image URL formats (jpg, png, gif, etc.)
    const imageUrlRegex = /\.(jpeg|jpg|gif|png|svg|webp)$/i;

    useEffect(() => {
        if (imageUrlRegex.test(imageUrl)) {
            setIsValidImageUrl(true);
        } else {
            setIsValidImageUrl(false);
        }
    }, [imageUrl]);

    const handleImageUpload = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
    };

    const handleRemoveButton = (index) => {
        const updatedButtons = actionButtons.filter((_, i) => i !== index);
        setActionIndex(updatedButtons.length)
        setActionButtons(updatedButtons);
    };
    const handleChangeAction = (index, e) => {

        const { name, value, type, checked } = e.target;

        const updatedButtons = actionButtons.map((button, i) =>
            i === index ? { ...button, [name]: type === 'checkbox' ? checked : value } : button
        );
        setActionButtons(updatedButtons);
    };

    const handleAddButton = () => {
        setActionIndex(actionButtons.length + 1)
        setActionButtons([...actionButtons, { id: '', label: '', iconPath: '', isSystemIcon: false }]);
    };


    return (
        <div >
            <div>

                <h3>Push Notification</h3>
            </div>
            <Card className='flex-row flex-wrap'>
                <div className="my-4 mx-4 col-md-6 col-sm-12 col-xs-12">
                    <Form onSubmit={handleSubmit} >
                        <div>


                            <Form.Group controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter message"
                                    value={message}
                                    onChange={(e) => {
                                        return setMessage(e.target.value)
                                    }}
                                />
                            </Form.Group>

                            {/* <Form.Group controlId="formImage">
                                <Form.Label>Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))}
                                />
                            </Form.Group> */}


                            <Form.Group controlId="formImage">
                                <Form.Label>Image</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Upload or input URL"
                                        value={imageUrl}
                                        onChange={(e) => {
                                            return setImageUrl(e.target.value)
                                        }}
                                    />
                                    <input
                                        type="file"
                                        id="upload"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }} // Hides the default input
                                    />
                                    <label htmlFor="upload" className="btn btn-outline-secondary" style={{ height: "fit-content" }}>
                                        Upload
                                    </label>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group controlId="largeIcon">
                                <Form.Label>Large Icon</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Input Resource Name"
                                    value={largeIcon}
                                    onChange={(e) => setLargeIcon(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="smallIcon">
                                <Form.Label>Small Icon</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Input Resource Name"
                                    value={smallIcon}
                                    onChange={(e) => setSmallIcon(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="formSound">
                                <Form.Label>Sound</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Default is device's default"
                                    value={sound}
                                    onChange={(e) => setSound(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="Lockscreen">
                                <Form.Label>Lockscreen Visibility</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={lockscreenVisibility}
                                    onChange={(e) => setLockscreenVisibility(e.target.value)}
                                >
                                    <option>Public</option>
                                    <option>Private</option>
                                    <option>Secret</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm="4">
                                    Priority <i className="bi bi-question-circle-fill"></i>
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Check
                                        type="radio"
                                        label="Normal"
                                        name="priority"
                                        id="priorityNormal"
                                        checked={priority === 'Normal'}
                                        onChange={() => setPriority('Normal')}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="High"
                                        name="priority"
                                        id="priorityHigh"
                                        checked={priority === 'High'}
                                        onChange={() => setPriority('High')}
                                        inline
                                    />
                                </Col>
                            </Form.Group>

                            {/* Time To Live Section */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label>
                                    Time To Live <i className="bi bi-question-circle-fill"></i>
                                </Form.Label>
                                <Col sm="8" className="d-flex">
                                    <Form.Control
                                        type="number"
                                        value={timeToLive}
                                        onChange={(e) => setTimeToLive(e.target.value)}
                                        min={1}
                                    />
                                    &nbsp;
                                    <Form.Control
                                        as="select"
                                        value={timeUnit}
                                        onChange={(e) => setTimeUnit(e.target.value)}
                                    >
                                        <option value="minutes">minutes</option>
                                        <option value="hours">hours</option>
                                        <option value="days">days</option>
                                        <option value="weeks">weeks</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            <div >
                                <Card>
                                    <Card.Header style={{ backgroundColor: "#e5e8eb" }}>Additional Data</Card.Header>
                                    <Card.Body>
                                        {keyValuePairs.map((pair, index) => (
                                            <Row key={index} >
                                                <Form.Label>Field {index + 1}</Form.Label>
                                                <Col md={5}>
                                                    <Form.Group controlId={`key-${index}`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="key"
                                                            value={pair.key}
                                                            onChange={(e) => handleChange(index, e)}
                                                            placeholder="Input Key"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={7} className='d-flex'>
                                                    <Form.Group controlId={`value-${index}`}>
                                                        {/* <Form.Label>Value</Form.Label> */}
                                                        <Form.Control
                                                            type="text"
                                                            name="value"
                                                            value={pair.value}
                                                            onChange={(e) => handleChange(index, e)}
                                                            placeholder="Input Value"
                                                        />
                                                    </Form.Group>
                                                    <div>

                                                        <Button style={{ backgroundColor: "white", color: "gray", border: "0", fontSize: "20px", marginTop: "-8%" }} onClick={() => handleRemovePair(index)}>
                                                            <RxCross1 />
                                                        </Button>
                                                    </div>
                                                </Col>
                                                {/* <Col md={2} >
                                                </Col> */}
                                            </Row>
                                        ))}
                                        <Button onClick={handleAddPair} style={{ backgroundColor: "white", color: "black", border: "0" }}>
                                            <HiPlusCircle /> Add Fields
                                        </Button>

                                    </Card.Body>
                                </Card>
                            </div>
                            <div style={{ marginTop: "5%" }}>
                                <Card>
                                    <Card.Header style={{ backgroundColor: "#e5e8eb" }}>Android Action Buttons</Card.Header>
                                    <Card.Body>
                                        {actionButtons.map((button, index) => (
                                            <Row key={index}>
                                                <Col md={5}>
                                                    <Form.Group controlId={`id-${index}`}>
                                                        <Form.Label>Action ID {index + 1}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="id"
                                                            value={button.id}
                                                            onChange={(e) => handleChangeAction(index, e)}
                                                            placeholder="e.g. accept-button"
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={5}>
                                                    <Form.Group controlId={`label-${index}`}>
                                                        <Form.Label>Button Label {index + 1}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="label"
                                                            value={button.label}
                                                            onChange={(e) => handleChangeAction(index, e)}
                                                            placeholder="e.g. Accept"
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={2}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: "white",
                                                            color: "gray",
                                                            border: "0",
                                                            fontSize: "20px",
                                                            marginTop: "25px",
                                                        }}
                                                        onClick={() => handleRemoveButton(index)}
                                                    >
                                                        <RxCross1 />
                                                    </Button>
                                                </Col>

                                                <Col md={5}>
                                                    <Form.Group controlId={`iconPath-${index}`}>
                                                        <Form.Label>Action Icon Path {index + 1}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="iconPath"
                                                            value={button.iconPath}
                                                            onChange={(e) => handleChangeAction(index, e)}
                                                            placeholder="e.g. hand.thumbsup"
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={5} style={{ marginTop: "5%" }}>
                                                    <Form.Group controlId={`isSystemIcon-${index}`}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="isSystemIcon"
                                                            checked={button.isSystemIcon}
                                                            onChange={(e) => handleChangeAction(index, e)}
                                                            label="System Icon"
                                                            style={{ marginTop: "5%" }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Button
                                            onClick={handleAddButton}
                                            className={actionIndex == 3 ? "disabled" : ""}
                                            style={{ backgroundColor: "white", color: "black", border: "0" }}
                                        >
                                            <HiPlusCircle /> Add Button
                                        </Button>
                                    </Card.Body>
                                </Card>

                            </div>
                        </div>
                        <div>
                            <Button variant="dark" type="submit" className="mt-3">
                                Review and Send
                            </Button>
                        </div>
                    </Form>
                    <Modal show={showModal} onHide={handleClose} className='modal-lg' style={{ maxWidth: '100%', marginTop: "50px" }} >
                        <Modal.Header closeButton>
                            <Modal.Title>Send Test Push</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control
                                type="text"
                                placeholder="Search by name"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="mb-3"
                            />
                            <Table striped bordered hover className='text-center' >
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Subscription ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appUsers.length && appUsers.map((sub, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectedSubscriptions.includes(sub.device_token)}
                                                    onChange={() => handleCheckboxChange(sub.device_token)}
                                                />
                                            </td>
                                            <td>{sub.name}</td>
                                            <td>{sub.mobile}</td>
                                            <td>{sub.token.substring(0, 20) + "..."}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <ToastContainer
                                position="top-right"
                                autoClose={2000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Slide}
                            />
                            <Button style={{ backgroundColor: "white", border: "0", color: "blue" }} onClick={handleClose}>
                                Cancel
                            </Button>
                            {<Button variant="secondary" onClick={handleSendTestPush}>
                                Send Test Push
                            </Button>}
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className='position-relative col-xs-12 col-sm-12 col-md-5'>
                    <div className="my-4 mx-4" style={{ position: "sticky", paddingTop: "1rem", top: "0px" }}>
                        <div className="mobile-image d-block">
                            <div className="notification-card">
                                <div className="profile">
                                    <img src={bellIcon} height="2px" alt="Profile" />
                                </div>
                                <div style={{ width: "85%" }}>
                                    <div>
                                        <p style={{ fontSize: "12px" }}>{appData.application_name}</p>
                                    </div>
                                    <div >
                                        <p style={{ fontSize: "14px", marginBottom: "2px" }}><b> {title}</b></p>
                                        <p style={{ color: displaymessage == 'Default message' ? "gray" : "black", fontSize: "14px", marginBottom: "8px" }}>{displaymessage}</p>
                                        {/* <div > */}
                                        {isValidImageUrl ? <img src={imageUrl} alt="Selected" style={{ padding: "2px", width: '85%', height: 'auto' }} /> : image ? <img src={image} alt="Selected" style={{ padding: "2px", width: '85%', height: 'auto' }} /> : ""}
                                        {/* </div> */}
                                        <div>
                                            {actionButtons.map((button, index) => (
                                                <button id='notification-button' style={{ border: "0", marginRight: "5px" }} key={button.id || index}>
                                                    {button.label}
                                                </button>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='position-relative'>

                    <div className="my-4 mx-4 offset-md-3 scrollable-form " style={{ position: "sticky", paddingTop: "1rem", top: "0px" }}>
                        <div className='mobile-image d-block'>
                            
                        </div>
                    </div>
                </div> */}

            </Card>
        </div>

    );
};

export default PushNotification;







// import React, { useState } from 'react';  
// import { Button } from 'react-bootstrap';  
// import TestPushModal from './TestPushModal';  

// const App = () => {  
//     const [showModal, setShowModal] = useState(false);  

//     const handleClose = () => setShowModal(false);  
//     const handleShow = () => setShowModal(true);  

//     return (  
//         <div className="p-4">  
//             <Button variant="primary" onClick={handleShow}>  
//                 Open Test Push Modal  
//             </Button>  
//             <TestPushModal show={showModal} handleClose={handleClose} />  
//         </div>  
//     );  
// };  

// export default App;