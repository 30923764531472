import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'selectize/dist/css/selectize.css'; // Import Selectize CSS
import 'selectize'; // Import Selectize jQuery plugin

const Selectize = ({ appList, type,handleShowOption ,handleSelectedOption }) => {
  const [organization,setOrganization] = useState("")
  // Reference to the select element
  const selectRef = useRef(null);

  useEffect(() => {
    // Initialize Selectize on the select element
    const $select = $(selectRef.current).selectize({
      sortField: 'text',
      onChange(value) {
        console.log(value);
        
        
        if (value !== 'create-new' && value.length) {
          console.log(handleSelectedOption);
          if (handleSelectedOption) {
            
            handleSelectedOption(value)
          }
          setOrganization(value)
          // You can implement your custom behavior here, e.g. open a modal, navigate to another page, etc.
        }
        
        if (value === 'create-new') {
          handleSelectedOption(value)
          handleShowOption()
          // You can implement your custom behavior here, e.g. open a modal, navigate to another page, etc.
        }
      },
    });

    // Get the Selectize instance
    const selectize = $select[0].selectize;



    // Cleanup function to destroy the Selectize instance properly
    return () => {
      if (selectize) {
        selectize.destroy();
      }
    };
  }, []);

  // Effect to handle changes in appList and update options
  useEffect(() => {
    const selectize = $(selectRef.current)[0].selectize;

    if (selectize) {
      // Clear all existing options
      selectize.clearOptions();

      // Add new options from appList
      appList.forEach((item) => {
        selectize.addOption({ value: type=="appCreation"?item.id: item.id, text: type=="appCreation"?item.oganization_name: item.application_name });
      });

      if (type == "appCreation") {
        
        // Add the special option for creating a new organization
        selectize.addOption({ value: 'create-new', text: '+ Create New Organization' });
      }

      // Refresh items in the dropdown
      selectize.refreshOptions(false);
    }
  }, [appList]);



  return (
    <>
    
    <style>
    {`
    .selectize-dropdown-content .option {
      height: 35px; 
      align-items: center;
      padding: 10px;
      font-size:17px;
      font-weight:500

    }

    .selectize-control.single .selectize-input {
      height: 40px; 
      display: flex;
      align-items: center;
      font-size:17px;
      font-weight:500;
    }

    .selectize-dropdown-content .option:hover {
      background-color: #764fb12e !important;
      color: black;
    }
    `}
  </style>
    <select   ref={selectRef} id="select-state" placeholder={type=="appCreation"?'+ Create New Organization': "Search apps"} onChange={(e) => setOrganization(e.target.value)} >
      {appList.length && appList.map((item) => (
        <option key={item.id} value={type=="appCreation"?item.id: item.application_name}>
          {type=="appCreation"?item.oganization_name: item.application_name}
        </option>
      ))}

    </select>
    </>
  );
};

export default Selectize;
