import React, { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

const TestModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const data = [
    { id: 1, name: 'Row 1' },
    { id: 2, name: 'Row 2' },
    { id: 3, name: 'Row 3' },
  ];

  const handleOpenModal = (id) => {
    setSelectedRowId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInputValue('');
  };

  const handleSubmit = () => {
    console.log('Row ID:', selectedRowId);
    console.log('Input Value:', inputValue);
    handleCloseModal();
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>
                <Button onClick={() => handleOpenModal(row.id)}>Open Modal</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Input Value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter value"
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TestModal;
