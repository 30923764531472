import React, { useEffect, useState } from 'react';
import Selectize from './Selectize';
import { useNavigate } from 'react-router-dom';
import { Utils } from '../index';
import Platforms from './Platforms';
import web from '../assets/icons/webimg.png';
const NewApp = ({changeSidebar}) => {
    const [showOption, setShowOption] = useState(false)
    const [firstStep, setFirstStep] = useState(false)
    const [channel, setChannel] = useState("")
    const [appList, setAppList] = useState([])
    const [appName, setAppName] = useState("")
    const [orgId,setOrgId] = useState(0)
    const [appNameAlert,setAppNameAlert] = useState(false)
    const [channelAlert,setChannelAlert] = useState(false)
    const [orgName,setOrgName] = useState("")
    const [website,setWebsite] = useState("")
    const [orgAlert,setOrgAlert] = useState(false)
    const [btnDisable,setBtnDisable] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        // /get-organizations
        const user = JSON.parse(localStorage.getItem('userData'));

        if (user) {

            fetch(Utils.baseurl + 'get-organizations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        console.log(result.data);
                        setAppList(result.data)

                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));
        }

    }, []);

	function generateRandomString() {
		// Helper function to generate a random hexadecimal string of a given length
		const randomHex = (length) => {
			return Math.random().toString(16).substr(2, length).padEnd(length, '0');
		};

		// Generate parts of the string
		const part1 = randomHex(8);
		const part2 = randomHex(4);
		const part3 = randomHex(4);
		const part4 = randomHex(4);
		const part5 = randomHex(12);

		// Combine parts with dashes
		return `${part1}-${part2}-${part3}-${part4}-${part5}`;
	}


    function handleShowOption() {
        setShowOption(true)
    }

    function saveFirstStep() {
        setBtnDisable(true)
        const user = JSON.parse(localStorage.getItem('userData'));
        if (!appName.length) {
            setAppNameAlert(true) 
        }
        if (!channel.length) {
            setChannelAlert(true)
        }
        if (orgId == 0 && !orgName.length ) {
            setOrgAlert(true)
        }
        let data = {application_name:appName,channel:channel, app_id:generateRandomString()}
        if (orgId!=0) {
            data.orgId = orgId
        }
        if (orgName.length) {
            data.orgName = orgName
            data.website = website
        }
        if (user) {
            data.userId = user.id 
        }
        console.log(data);
        if (appName.length && channel.length && (orgId != 0 || orgName.length)) {
            fetch(Utils.baseurl + 'save/application', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    console.log(result.data);
                    
                    if (result.status) {
                        changeSidebar("defaultSidebar",1)
                        navigate(`/apps/new/${result.data.app_id}/setting/configure/${result.data.applicationDetails[0].channel_type}`)
                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));
        }
        // setFirstStep(true) 
    }
 
    function handleSelectedOption(params) {
        if (params == "create-new") {
            setOrgId(0)
        }else{
            setShowOption(false)
            setOrgName("")
            setOrgId(params)
        }

      
    }

    useEffect(() => {

        if (appName.length) {
            setAppNameAlert(false) 
        }
        if (channel.length) {
            setChannelAlert(false) 
        }
        if (orgId != 0 || orgName.length ) {
            setOrgAlert(false)
        }

    }, [appName,orgName,orgId,channel]);



    return (
        <div className="dashboard_right">
            {
                <div className="dashboard_inner">
                    <div className="dashboard_top">
                        <div className="dashboard_heading">
                            <h1>New NotifySphere App</h1>
                        </div>
                        <div className="sub_heading">
                            Set up a new NotifySphere app to get sending. Need help? <a href="#">Read our getting started docs</a>
                        </div>
                    </div>
                    <div className="app_plateform">
                        <div className="app_plateform_field">
                            <div className="app_field_inner">
                                <label htmlFor="app-name">NotifySphere App Name</label>
                                <div className="app_input">
                                    <input type="text" value={appName} onChange={(e) => setAppName(e.target.value)} />
                                </div>
                              {appNameAlert?  <div className="validation_error">
                                    <span>
                                        <img src="error_icon.svg" alt="" />
                                        Please enter a response</span>
                                </div>:""}
                            </div>
                        </div>
                        <div className="app_plateform_field">
                            <div className="app_field_inner">
                                <label htmlFor="organization-select">What organization should it belong to?</label>
                                <div className="app_input">
                                    <div className="search_app_dropdown">
                                        {/* <select id="select-state" placeholder="+ Create New Organization">
                                    <option value="">+ Create New Organization</option>
                                    <option value="test">Test</option>
                                </select> */}
                                        <Selectize appList={appList} type={"appCreation"} handleShowOption={handleShowOption} handleSelectedOption={handleSelectedOption} />
                                    </div>
                                </div>
                                    {orgAlert?  <div className="validation_error">
                                    <span>
                                        <img src="error_icon.svg" alt="" />
                                        Please enter a response</span>
                                </div>:""}
                            </div>
                        </div>
                        {showOption ?
                            <>
                                <div className="app_plateform_field">
                                    <div className="app_field_inner">
                                        <label htmlFor="org-name">Organization Name</label>
                                        <div className="app_input">
                                            <input type="text" value={orgName} onChange={(e)=>setOrgName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="app_plateform_field">
                                    <div className="app_field_inner">
                                        <label htmlFor="organization-website">Organization's Website (optional)</label>
                                        <div className="app_input">
                                            <input type="text" value={website} onChange={(e)=>setWebsite(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </>
                            : ""}
                        <div className="app_select_field">
                            <div className="app_field_inner">
                                <label>Set up your first channel</label>
                                <div className="select_app">
                                    <ul>
                                        <li>
                                            <input type="radio" name="radio" value={channel}  onChange={() => setChannel("apple-ios")} />
                                            <div className="select_inner">
                                            <svg  style={{ height: "40px", width: "40%" }} viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg>

                                                <span style={{marginTop:"8px"}}>Apple iOS (FCM)</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" name="radio" value={channel}  onChange={() => setChannel("google-android")}/>
                                            <div className="select_inner">
                                            <svg  style={{ height: "40px", width: "40%" }} viewBox="0 0 1274.37 718" fill="currentColor" className="sc-jSMfEi hKsxtk"><path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84"></path></svg>

                                                <span style={{textAlign:"center"}}>Google Android <br /> (FCM)</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" name="radio" value={channel}  onChange={() => setChannel("web")} />
                                            <div className="select_inner">
                                                <img src={web} width="55px" alt="" />
                                                <span style={{marginTop:"5px"}}>Web</span>
                                            </div>
                                        </li>

                                    </ul>

                          {   channelAlert?<div className="validation_error">
                                        <span>
                                            <img src="error_icon.svg" alt="" />
                                            Please enter a response</span>
                                    </div>:""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="next_button">
                        <button onClick={saveFirstStep} disabled={btnDisable?true:false} type="button" name="button">Next: Configure Your Platform</button>
                    </div>
                </div>
            }
        </div>
    );
};

export default NewApp;
