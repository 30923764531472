
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const signUp = (token) => {
    setIsAuthenticated(true);
    localStorage.setItem('token', token);
  };


  const signIn = (token) => {
    setIsAuthenticated(true);
    localStorage.setItem('token', token);
  };

  const signOut = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('currentSidebar');
    localStorage.removeItem('thirdSidebar');
    localStorage.removeItem('activeApp');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, signIn, signOut ,signUp}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
