import React, { useEffect, useRef, useState } from 'react';
import Selectize from './Selectize';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Utils } from '../index';
import web from '../assets/icons/webimg.png';
import bellIcon from '../assets/icons/bellIcon.png'
import { BsPencil } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";
import loader from '../assets/loader2.gif'
const PushSetting = ({ }) => {
    const [groupName, setGroupName] = useState("")
    const [group, setGroup] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [showChannelModal, setShowChannelModal] = useState(false);
    const dropdownRef = useRef(null);
    const { id } = useParams();

    const [channelName, setChannelName] = useState('');
    const [channelDescription, setChannelDescription] = useState('');
    const [importance, setImportance] = useState('Low');
    const [led, setLed] = useState('Off');
    const [sound, setSound] = useState('Off');
    const [vibration, setVibration] = useState('Off');
    const [badges, setBadges] = useState('Enabled');
    const [lockscreen, setLockscreen] = useState('Public');
    const [notificationGroupId, setNotificationGroupId] = useState(0);

    const [channelUpdateId, setChannelUpdateId] = useState(0);
    const [groupUpdateId, setGroupUpdateId] = useState(0);
    const [notificationChannelId, setNotificationChannelId] = useState("");
    const [isLoading,setIsLoading] = useState(true)
    const [channelNameErr,setChannelNameErr] = useState(false)

console.log(importance);

    function renderGroupList() {
        setIsOpen(0)
        fetch(Utils.baseurl + 'getall/notification/group', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.status) {
                    setIsLoading(false)
                    console.log(result.data);
                    setGroup(result.data)
                    setShowChannelModal(false);

                }
            })
            .catch((error) => console.error(error));
    }


    useEffect(() => {

        renderGroupList()


    }, []);

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(null); // Close dropdown if click is outside

            }
        };

        // Attach the event listener when dropdown is open
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener when component unmounts or dropdown closes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
       if (importance == "Low" || importance == 'Medium') {
        console.log("hello");
        
        setSound("Off")
        setVibration("Default")
       }
    }, [importance]);




    const handleSubmit = () => {
        console.log('Input Value:', inputValue);
        setGroupName(inputValue)
        let data = { id: id, name: inputValue }

        if (groupUpdateId) {
            data.groupId = groupUpdateId
        }

        if (inputValue.length) {

            fetch(Utils.baseurl + 'save/notification/group', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        setGroup([result.data])
                        renderGroupList()
                        handleCloseModal();
                    }
                })
                .catch((error) => console.error(error));
        }

        handleCloseModal();
    };


    const handleSubmitChannel = () => {


        console.log('Input Value:', { channelName, channelDescription, importance, led, badges, lockscreen });


        let data = {
            "notificationGroupId": notificationGroupId,
            "name": channelName,
            "description": channelDescription,
            "LED": 1,
            "ledDetail": led,
            "vibration": 1,
            "vibrationDetail": vibration,
            "sound": sound,
            "badge": badges == 'Enabled' ? 1 : 0,
            "Importance": importance == 'Low' ? 2 : importance == 'Medium' ? 4 : 'High' ? 6 : 8,
            "lockScreen": lockscreen == 'Public' ? 1 : lockscreen == 'Private' ? 0 : -1
        }

        if (channelUpdateId) {
            data.id = channelUpdateId
        }

        console.log(data);


        if (channelName.length) {

            fetch(Utils.baseurl + 'save/notification/channel', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        renderGroupList()
                    }
                })
                .catch((error) => console.error(error));
        }

    };

    function handleDeleteChannel(id) {
        console.log(id);

        fetch(Utils.baseurl + 'delete/notification/channel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.status) {
                    renderGroupList()
                    setShowChannelModal(false);
                }
            })
            .catch((error) => console.error(error));

    }

    function handleDeleteGroup(id) {
        console.log(id);
        setIsOpen(false)
        fetch(Utils.baseurl + 'delete/notification/group', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.status) {
                    renderGroupList()
                }
            })
            .catch((error) => console.error(error));


    }

    const handleOpenModal = (id) => {
        console.log(id);
        setGroupUpdateId(id)
        setShowModal(true);
        setInputValue('');
    };
    const handleUpdateModal = (id, name) => {
        console.log(id);
        setGroupUpdateId(id)
        setShowModal(true);
        setInputValue(name);
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setInputValue('');
    };
    const handleChannelOpenModal = (id) => {
        setNotificationChannelId("")
        setChannelName("")
        setChannelDescription("")
        setImportance('Low')
        setLed('Off')
        setSound('Off')
        setVibration('Off')
        setBadges('Enabled')
        setLockscreen('Public')
        setChannelUpdateId(0)
        setNotificationGroupId(id)
        setShowChannelModal(true);


    };
    const handleChannelCloseModal = () => {
        setShowChannelModal(false);
    };



    function handleOpenDropdown(id) {
        setIsOpen(id)
    }

    function handleChannelUpdate(data) {
        console.log(data.id);

        setShowChannelModal(true)
        setChannelUpdateId(data.id)
        console.log(data);
        setNotificationChannelId(data.channel_id)
        setChannelName(data.name)
        setChannelDescription(data.description)
        setImportance(data.Importance == 2 ? 'Low' : data.Importance == 4 ? "Medium" : data.Importance == 6 ? "High" : 'Urgent')
        setLed(data.ledDetail)
        setSound(data.sound)
        setVibration(data.vibrationDetail)
        setBadges(data.badge ? 'Enabled' : 'Disabled')
        setLockscreen(data.lockScreen == 1 ? 'Public' : data.lockScreen == -1 ? 'Secret' : 'Private')

    }
    console.log(group);


    const handleCopy = () => {
        navigator.clipboard.writeText(notificationChannelId)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };
    // style={{ backgroundColor: "#e5e8eb" }}


    return (
        <div className="dashboard_right">
            {
                !isLoading?    <div className="dashboard_inner">
                    <div className="dashboard_top">
                            <div className="dashboard_heading">
                                    <h1>Messaging Channel</h1>
                                </div>

                        <div class="plateform_card inactive_plateform">
                            {group.length && !isLoading ?
                                <div class="card_top">
                                    <div class="card_top_left">
                                        <div class="card_top_inner">
                                            <h3 color="black">Android Notification Channels</h3>

                                            <div class="active_inactive">

                                            </div>
                                        </div>
                                        <div className="sub_heading">
                                            <div className='d-flex'>

                                                <div>
                                                    <svg style={{ width: "50px", marginRight: "10px" }} viewBox="0 0 1274.37 718" fill="currentColor"> <path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84"> </path> </svg>
                                                </div>
                                                <div>
                                                    Notification channels are an Android 8.0 (API level 26) feature which gives users finer control over notifications. <br /> <a style={{ color: "rgb(72 85 177)" }} href="#">Channels documentation.</a>

                                                </div>
                                            </div>
                                        </div>



                                        {group.map(item => (
                                            <div key={"groupItem"+item.id} class="plateform_card inactive_plateform" style={{ width: "500px" }}>
                                                <div class="card_top">
                                                    {
                                                        <div class="card_top_left">
                                                            <div class="card_top_inner">
                                                                <h3 color="black">{item.name}</h3>

                                                                <div class="active_inactive">

                                                                </div>
                                                            </div>

                                                            {item.notificationChannels ? item.notificationChannels.map(channel => (
                                                                <div key={channel.id} onClick={() => handleChannelUpdate(channel)} class="d-flex mb-3" style={{ cursor: "pointer", width: "116%", borderRadius: "5px", backgroundColor: "#ececfc" }}>
                                                                    <div class="p-2"> <img src={bellIcon} style={{ height: "20px", marginRight: "5px" }} alt="" /></div>
                                                                    <div class="p-2">{channel.name}</div>
                                                                    <div class="ms-auto p-2" style={{ cursor: "pointer" }}> <BsPencil style={{ fontSize: "20px" }} /></div>
                                                                </div>
                                                            )) : ""}
                                                            <div style={{ marginLeft: "25px" }}>

                                                                <Link style={{ color: "rgb(72 85 177)" }} onClick={() => handleChannelOpenModal(item.id)}>
                                                                    <svg style={{ width: "17px" }} viewBox="0 0 16 16" fill="currentColor">
                                                                        <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                                                                        </path>
                                                                    </svg> &nbsp;
                                                                    Add Channel</Link>
                                                            </div>

                                                        </div>

                                                    }

                                                    <div class="card_top_right">
                                                        <div onClick={() => handleOpenDropdown(item.id)}>
                                                            <svg style={{ height: "20px", cursor: "pointer" }} viewBox="0 0 16 16" fill="currentColor">
                                                                <path d="M8 5.25A1.625 1.625 0 108 2a1.625 1.625 0 000 3.25zM8 9.625a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM9.625 12.375a1.625 1.625 0 11-3.25 0 1.625 1.625 0 013.25 0z" fill="currentColor">
                                                                </path>
                                                            </svg>
                                                        </div>
                                                        {isOpen == item.id ? <div ref={dropdownRef} style={{ position: "absolute", right: "8%" }}>
                                                            <div className='dropdown-menu3' style={{ width: "80px" }} >
                                                                <button onClick={() => handleUpdateModal(isOpen, item.name)}>
                                                                    Edit
                                                                </button>
                                                                <button className="delete-class"
                                                                    style={{ color: "red" }}
                                                                    onClick={() => handleDeleteGroup(item.id)}
                                                                >
                                                                    {/* <RxCross2 className="dashboardsvg" color="red" /> */}
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </div> : ""}
                                                    </div>
                                                </div>


                                            </div>
                                        ))}

                                        <div style={{ marginLeft: "25px" }}>

                                            <Link style={{ color: "rgb(72 85 177)" }} onClick={() => handleOpenModal(0)}>
                                                <svg style={{ width: "17px" }} viewBox="0 0 16 16" fill="currentColor">
                                                    <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                                                    </path>
                                                </svg> &nbsp;
                                                Add Group</Link>
                                        </div>


                                    </div>
                                </div> :
                             !group.length &&  !isLoading?
                                <>

                                    <div class="card_top">
                                        <div class="card_top_inner">
                                            <h3 >No groups created. Create a group to add notification channels</h3>

                                            <div class="active_inactive">

                                            </div>
                                        </div>

                                    </div>
                                    <div class="card_footer">
                                        <div class="card_top_left">
                                            <div class="card_top_inner">
                                                <div class="card_setting">
                                                    <Link style={{ color: "rgb(72 85 177)" }} onClick={() => handleOpenModal(0)}>Add Group</Link>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </>
                                :""


                            }
                            {showModal ?
                                <Modal show={showModal} onHide={handleCloseModal} >
                                    <Modal.Header closeButton>
                                        <Modal.Title>{groupUpdateId ? "Edit Notification Group" : "Create Notification Group"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <span style={{ fontSize: "15px" }}> Name :</span>

                                        <input
                                            style={{ marginTop: "6px" }}
                                            type="text"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            placeholder=""
                                            className="form-control"
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button style={{ background: "none", border: "0", marginRight: "12px", color: "rgb(67, 70, 206)" }} onClick={handleCloseModal}>
                                            Cancel
                                        </button>
                                        <div className="add_app" onClick={handleSubmit}>
                                            <Link >
                                                <span>{groupUpdateId ? "Update" : "Add"}</span>
                                            </Link>
                                        </div>
                                    </Modal.Footer>
                                </Modal> : showChannelModal ? <Modal show={showChannelModal} onHide={handleChannelCloseModal} className="custom-modal-size">
                                    <Modal.Header closeButton>
                                        <Modal.Title>{channelUpdateId ? "Update Notification Channel" : "Create Notification Channel"}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        {notificationChannelId.length ? <>
                                            <span>Channel ID: <code >{notificationChannelId} </code>
                                                <button type="button" style={{ border: "none" }} onClick={handleCopy}>
                                                    <MdContentCopy />
                                                </button>
                                            </span> <br /><br />
                                        </>
                                            : ""}


                                        <span style={{ fontSize: "17px" }}> User-visible channel name :</span>

                                        <input
                                            style={{ marginTop: "6px" }}
                                            type="text"
                                            value={channelName}
                                            onChange={(e) => setChannelName(e.target.value)}
                                            placeholder="Channel Name"
                                            className="form-control"
                                        />
                                        <span style={{ fontSize: "17px" }}> User-visible channel description :</span>

                                        <textarea
                                            style={{ marginTop: "6px" }}
                                            type="text"
                                            value={channelDescription}
                                            onChange={(e) => setChannelDescription(e.target.value)}
                                            className="form-control"
                                        />


                                        <fieldset style={{
                                            padding: "10px",
                                        }}>
                                            <span style={{ fontSize: "17px" }}> <b>Importance</b> : How interruptive the notification will be</span><br />
                                            {['Low', 'Medium', 'High', 'Urgent'].map(level => (
                                                <>

                                                    <label style={{

                                                        marginTop: " 2px"
                                                    }
                                                    } key={level}>
                                                        <input
                                                            type="radio"
                                                            name="importance"
                                                            value={level}
                                                            checked={importance === level}
                                                            onChange={() => setImportance(level)}
                                                        /> &nbsp;
                                                        {level}
                                                    </label>
                                                    <br />
                                                </>
                                            ))}
                                        </fieldset>
                                   {   
                                  importance=='High' || importance =='Urgent'? <>
                                   
                                   <fieldset style={{
                                            padding: "10px",
                                        }}>
                                            <span style={{ fontSize: "17px" }}> <b>Sound</b> : Play a sound when the notification is received
                                            </span><br />
                                            {['Off', 'Default', 'Custom'].map(option => (
                                                <>
                                                    <label style={{

                                                        marginTop: " 2px"
                                                    }
                                                    } key={option}>
                                                        <input
                                                            type="radio"
                                                            name="sound"
                                                            value={option}
                                                            checked={sound === option}
                                                            onChange={() => setSound(option)}
                                                        /> &nbsp;
                                                        {option}
                                                    </label>
                                                    <br />
                                                </>
                                            ))}

                                        </fieldset>
                                        
                                        <fieldset style={{
                                            padding: "10px",
                                        }}>
                                            <span style={{ fontSize: "17px" }}> <b>Vibration</b> : Vibrate the device when the notification is received</span><br />
                                            {['Off', 'Default', 'Custom'].map(option => (
                                                <>
                                                    <label style={{

                                                        marginTop: " 2px"
                                                    }
                                                    } key={option}>
                                                        <input
                                                            type="radio"
                                                            name="vibration"
                                                            value={option}
                                                            checked={vibration === option}
                                                            onChange={() => setVibration(option)}
                                                        /> &nbsp;
                                                        {option}
                                                    </label>
                                                    <br />
                                                </>
                                            ))}

                                        </fieldset>
                                   </>:""
                                        }

                                        <fieldset style={{
                                            padding: "10px",
                                        }}>
                                            <span style={{ fontSize: "17px" }}> <b>LED</b> : Devices that have one will blink in the chosen color</span><br />
                                            {['Off', 'Default', 'Custom'].map(option => (
                                                <>
                                                    <label style={{

                                                        marginTop: " 2px"
                                                    }
                                                    } key={option}>
                                                        <input
                                                            type="radio"
                                                            name="led"
                                                            value={option}
                                                            checked={led === option}
                                                            onChange={() => setLed(option)}
                                                        /> &nbsp;
                                                        {option}
                                                    </label>
                                                    <br />
                                                </>
                                            ))}

                                        </fieldset>






                                        <fieldset style={{
                                            padding: "10px",
                                        }}>
                                            <span style={{ fontSize: "17px" }}><b>Badges</b>: Show a badge number or notification dot on the app icon</span><br />
                                            {['Enabled', 'Disabled'].map(option => (
                                                <>

                                                    <label style={{

                                                        marginTop: " 2px"
                                                    }
                                                    } key={option}>
                                                        <input
                                                            type="radio"
                                                            name="badges"
                                                            value={option}
                                                            checked={badges === option}
                                                            onChange={() => setBadges(option)}
                                                        /> &nbsp;
                                                        {option}
                                                    </label>
                                                    <br />
                                                </>
                                            ))}
                                        </fieldset>

                                        <fieldset style={{
                                            padding: "10px",
                                        }}>
                                            <span style={{ fontSize: "17px" }}><b>Lockscreen</b>: Visibility privacy</span><br />
                                            {['Public', 'Private', 'Secret'].map(option => (
                                                <>

                                                    <label style={{

                                                        marginTop: " 2px"
                                                    }
                                                    } key={option}>
                                                        <input
                                                            type="radio"
                                                            name="lockscreen"
                                                            value={option}
                                                            checked={lockscreen === option}
                                                            onChange={() => setLockscreen(option)}
                                                        /> &nbsp;
                                                        {option}
                                                    </label>
                                                    <br />
                                                </>
                                            ))}
                                        </fieldset>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        {channelUpdateId ? <button style={{ background: "none", border: "0", marginRight: "12px", color: "rgb(67, 70, 206)" }} onClick={() => handleDeleteChannel(channelUpdateId)}>
                                            delete
                                        </button> : ""}
                                        <div className="add_app" onClick={handleSubmitChannel}>
                                            <Link >
                                                <span>{channelUpdateId ? "Update" : "Create"}</span>
                                            </Link>
                                        </div>

                                    </Modal.Footer>
                                </Modal> : ""
                            }
                        </div>



                    </div>

                </div>
                :  <div style={{display: "flex",justifyContent: "center",height:"45px"}}>
                        <img src={loader} alt="" />
                </div>   
            }
        </div >
    );
};

export default PushSetting;
