import React, { useEffect, useState } from "react";
import '../App.css'
import { Utils } from '../index';
import Loader from "../assets/loader.gif"
import SkeletonLoader from "./SkeletonLoader";
const Organizations = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    const [plan,setPlan] = useState("")
    const [appList, setAppList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
         
        fetch(Utils.baseurl + 'user/getbyid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userData.id }),
        })
            .then((response) => response.json())
            .then(async (result) => {
                if (result.status) {
                    setIsLoading(false)
                    if (result.data) {
                        if (result.data.plans[0].plan_type == 1) {
                            setPlan("free")
                        }
                        if (result.data.plans[0].plan_type == 2) {
                            setPlan("Growth")
                        }
                        if (result.data.plans[0].plan_type == 3) {
                            setPlan("Professional")
                        }
                        if (result.data.plans[0].plan_type == 4) {
                            setPlan("Enterprise")
                        }
                        setAppList(result.data.organizations)
                        console.log(result.data);
        }
                    
                } else {
                    alert(result.message)
                }
            })
            .catch((error) => console.error(error));
        





        // fetch(Utils.baseurl + 'get-organizations', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({userId:userData.id}),
        // })
        //     .then((response) => response.json())
        //     .then(async (result) => {
        //         console.log(result.data);
                
        //         if (result.status) {
    
        //             if (result.data) {
        //                 setAppList(result.data)
        //             }
                    
        //         } else {
        //             alert(result.message)
        //         }
        //     })
        //     .catch((error) => console.error(error));



    }, []);


    let loaderData =  [
        {
          id: 1,
          name: 'test',
        },
        {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          }
      ]

    return (
        <div className="dashboard_right">
            <div className="dashboard_inner">
                <div className="dashboard_right_top">
                    <div className="dashboard_heading">
                        <h1>Organizations</h1>
                    </div>
                    <div className="add_app">
                        <a  href="/apps/new">
                            <svg viewBox="0 0 16 16" fill="currentColor">
                                <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                                </path>
                            </svg>
                            {/* <img src={Loader} alt="" /> */}
                            <span>New Organization</span>
                        </a>
                    </div>
                </div>
                <div className="dashboard_app_table">
                  
                    <div className="dashboard_table">
                        <div className="table_inner">
                            <table className="table_outer">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Plan</th>
                                        <th style={{textAlign:"center"}}>Mobile (MAU)</th>
                                        <th style={{textAlign:"center"}}>Web Push Subscribers</th>
                                        <th style={{textAlign:"center"}}>Total Records</th>
                                        <th style={{textAlign:"center"}}>Total Subscribed</th>
                                        <th>Apps</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                     {appList.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" style={{ textAlign: "center" }}>
                                                {
                                                    isLoading ?
                                                        // <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> 
                                                        <SkeletonLoader columns={loaderData} rows={2} />
                                                        :<p style={{ fontSize: 20, fontWeight: 500 }} >No data found</p>
                                                }
                                            </td>
                                        </tr>
                                    ) : appList.map(item => (
                                        <tr key={item.id}>
                                            <td>
                                                <a href="#">{item.oganization_name}</a>
                                            </td>
                                            <td className="plan"><span>{plan}</span></td>
                                            <td style={{textAlign:"center"}}>0</td>
                                            <td style={{textAlign:"center"}}>0</td>
                                            <td style={{textAlign:"center"}}><span>0</span> &nbsp;(+0%)</td>
                                            <td style={{textAlign:"center"}}><span>0</span></td>
                                            <td>
                                            <td style={{textAlign:"center"}}><span>{item.applications.length}</span></td>
                                            </td>
                                            <td>
                                                <button type="button">
                                                    <svg viewBox="0 0 16 16" fill="currentColor">
                                                        <path d="M8 5.25A1.625 1.625 0 108 2a1.625 1.625 0 000 3.25zM8 9.625a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM9.625 12.375a1.625 1.625 0 11-3.25 0 1.625 1.625 0 013.25 0z" fill="currentColor">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Organizations;
