import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Row, ListGroup } from 'react-bootstrap';
const Dashboard = ({ handleSetAppId }) => {
    const [viewport, setViewport] = useState({ width: window.innerWidth, height: window.innerHeight });
    const { id } = useParams()


    useEffect(() => {
        handleSetAppId(id)
        const handleResize = () => {
            setViewport({ width: window.innerWidth, height: window.innerHeight });
        };


        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [id]);

    return (
        <>
        <div className="dashboard_right_top">
            <div className="dashboard_heading">
                <h2 className="mb-4">Dashboard</h2>
            </div>

            <div className="add_app">

                <Link to={`/apps/${id}/push-notification`}>


                    <svg viewBox="0 0 16 16" fill="currentColor">
                        <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                        </path>
                    </svg>
                    <span>New Message</span>

                </Link>


            </div>


        </div>
            <div className="plateform_cards">
            <div className="plateform_card">
              <div className="card_top">
                <div className="card_top_left">
                  <div className="card_top_inner">
                    <span color="black">Total Subscription Records</span>
                    <div className="active_inactive">

                    </div>
                  </div>
                </div>
                <div className="card_top_right">
                  {/* <svg viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg> */}
         
                </div>
              </div>
              <div className="card_footer">
                <div className="card_setting">
                <h2>0</h2>
                  {/* <a href="#">Activate
                  </a> */}
                </div>
                <div>
                </div>
              </div>
            </div>


            <div className="plateform_card">
              <div className="card_top">
                <div className="card_top_left">
                  <div className="card_top_inner">
                    <h3 color="black"></h3>
                    <div className="active_inactive">

                    </div>
                  </div>
                </div>
                <div className="card_top_right">
                  {/* <svg viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg> */}
                </div>
              </div>
              <div className="card_footer">
                <div className="card_setting">
                  {/* <a href="#">Activate
                  </a> */}
                </div>
                <div>
                </div>
              </div>
            </div>
            </div>

     

        </>
        
    );
};

export default Dashboard;
