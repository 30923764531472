import React, { useState } from 'react';
import "../App.css"

const Dropdown = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

  const handleAction = action => {
    console.log(`${action} clicked`);
    // Handle actions like Open Dashboard, Rename, and Delete here
    // setIsOpen(false);
  };

  return (
    <div className='dropdown'>
        <div className='dropdown-menu'>
          <button onClick={() => handleAction('Open Dashboard')}>
            Open Dashboard
          </button>
          <button onClick={() => handleAction('Rename')}>Rename</button>
          <button
            className='delete-button'
            onClick={() => handleAction('Delete')}
          >
            Delete
          </button>
        </div>

    </div>
  );
};

export default Dropdown;
