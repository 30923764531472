import React from "react";

const SkeletonLoader = ({ columns, rows }) => {
  return (
    <table className="skeleton-table">
      {/* <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index} style={{ width: col.width }}>{col.label}</th>
          ))}
        </tr>
      </thead> */}
      <tbody>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>
                <div className="skeleton-cell"></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SkeletonLoader;