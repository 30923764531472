import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaPencilAlt } from "react-icons/fa";
import { Utils } from '../index';


const RenameApplication = (props) => {
  const [show, setShow] = useState(false);
  const [topic, setTopic] = useState(props.data.name); // State to manage input field value

  console.log("showhhh ",show);
  const handleClose = () => {
    console.log("hello handleClose");
    
    setShow(false)};
  const handleShow = () => {
    console.log("hello handleShow");
    setShow(true)};



console.log(props);



  // Function to handle input field change
  const handleInputChange = (e) => {
    console.log(e.target.value);
    setTopic(e.target.value);
  };






  // Function to handle saving changes
  const handleSaveChanges = async () => {
    // Call your update title API here with the new title value
    // For demonstration purposes, I'm just logging the new title value
    let data = {title:typeof(topic) == 'string'? topic.trim():topic.topic.trim(), id: props.data.id,  }
  
    console.log("data");

    // if( data.title.length){
    //   const response = await fetch(Utils.baseurl + 'update/application', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data),
    //   });
    // //   props.renderFun()
    //   // handleClose(); // Close the modal after saving changes

    // }else{
    //   alert("Please Enter Topic and select atleast one Category")
    // }
  
  };





  return (
    <>
      <Button style={{ borderColor: "lightGray", color: "black", alignItems: "center" }} onClick={handleShow}>
        <FaPencilAlt style={{ marginRight: 10, marginLeft: 10 }} /> Rename
      </Button>

      <Modal show={show}  keyboard={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Input field for editing title */}
          <div className="d-flex" style={{ marginBottom: "10px", fontSize: "large" }}>
            <div style={{ marginLeft: "10px" }}><b>Title</b></div>
          </div>

          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <Form.Control
              type="text"
              defaultValue={topic}
              onChange={handleInputChange}
              style={{ fontSize: "20px", width: "50%" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{backgroundColor:"#484848",borderColor:"#484848"}} onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenameApplication;
