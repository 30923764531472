import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router";
import { Utils } from '../index';
import { Link } from 'react-router-dom';
import web from '../assets/icons/webimg.png';
import loader from '../assets/loader2.gif'

const Platforms = ({changeSidebar}) => {
  
  const [fileName, setFileName] = useState(null);
  const [activeApp, setActiveApp] = useState({});
  const [jsonContent, setJsonContent] = useState(null);
  const [channelType, setChannelType] = useState(null)
  const [appDetails, setAppDetails] = useState([])
  const navigate = useNavigate();
  const { appId } = useParams();
  const [inactiveChannel, setInactiveChannel] = useState([])
  const [isLoading,setIsLoading] = useState(true)


  useEffect(() => {
    // /getbyid/application

    fetch(Utils.baseurl + 'getbyid/application', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: appId }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        let allchannels = [{ channel_type: 1 }, { channel_type: 2 }, { channel_type: 3 }]
        if (result.status) {
          console.log(result.data);
          setChannelType(result.data.channel_type)
          setAppDetails(result.data.applicationDetails)
          setIsLoading(false)
          if (result.data.applicationDetails.length) {
            let arr2ChannelTypes = result.data.applicationDetails.map(item => item.channel_type);
            let inactiveChannels = allchannels.filter(item => !arr2ChannelTypes.includes(item.channel_type));
            setInactiveChannel(inactiveChannels)
          } else {
            setInactiveChannel(allchannels)
          }
        } else {
          alert(result.message)
        }
      })
      .catch((error) => console.error(error));




  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/json') {
      setFileName(file.name);
      const reader = new FileReader();

      // Read the file content
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);  // Parse JSON content
          setJsonContent(json);
        } catch (err) {
          alert('Invalid JSON file.');
        }
      };

      reader.readAsText(file); // Read file as text
    } else {
      alert('Please upload a valid JSON file.');
    }
  };

  useEffect(() => {
    setActiveApp(JSON.parse(localStorage.getItem('application')).find(item => item.is_active === 1))
  }, [localStorage.getItem('application')]);

  const handleSave = () => {
    if (jsonContent) {
      let data = {
        id: activeApp.id,
        application_json: JSON.stringify(jsonContent),
        json_name: fileName
      }
      fetch(Utils.baseurl + 'update/application', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (result) => {
          if (result.status) {
            // handleSetOnboard()
            navigate(`/push-notification`);
          } else {
            alert(result.message)
          }
        })
        .catch((error) => console.error(error));

      // Here you can handle the JSON data, like sending it to a server or using it in the app
      alert('JSON file processed successfully.');
      navigate("/push-notification")
    } else {
      alert('No JSON data to save.');
    }
  };

  const handleSetting = (e) => {
    const isAllowed = window.confirm("Are you sure you want to Setting Platform?");
    if (!isAllowed) {
      e.preventDefault(); // Cancel navigation if condition is not met
    }else{
      changeSidebar("dashboardSidebar",1)
    }
  };
  

  return (
<div className="dashboard_right">
{!isLoading?  <div className="dashboard_inner">

  

            <div className="plateform_section">
              {appDetails.length ?<h2>Active Platforms</h2>:""}
              <div className="plateform_cards">
              {  appDetails.length ? appDetails.map(item => (
                <div key={item.id} className="plateform_card">
                  <div className="card_top">
                    <div className="card_top_left">
                      <div className="card_top_inner">
                        {item.channel_type == 2 ? <h3 color="black">Google Android (FCM)</h3> : <h3 color="black"> Apple iOS (FCM)</h3>}
                        <div className="active_inactive">
                          <span>Active</span>
                        </div>
                      </div>
                    </div>
                    <div className="card_top_right">
                      {item.channel_type == 2 ? <svg viewBox="0 0 1274.37 718" fill="currentColor">
                        <path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84">
                        </path>
                      </svg> : <svg viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg>}
                    </div>
                  </div>
                  <div className="card_footer">
                    <div className="card_setting">
                      <Link to={`/apps/new/${appId}/setting/configure/${item.channel_type}`} onClick={handleSetting} >
                        <svg viewBox="0 0 16 16" fill="currentColor">
                          <g fill="currentColor">
                            <path d="M14.948 6.51l-.844-.3a.915.915 0 01-.52-1.257l.384-.809a1.584 1.584 0 00-2.11-2.11l-.81.385a.916.916 0 01-1.256-.522l-.3-.843a1.584 1.584 0 00-2.984 0l-.3.844a.917.917 0 01-1.256.52l-.81-.384a1.584 1.584 0 00-2.11 2.11l.385.809a.917.917 0 01-.52 1.257l-.844.3a1.583 1.583 0 000 2.983l.844.3a.916.916 0 01.52 1.257l-.384.809a1.584 1.584 0 002.11 2.11l.809-.384a.915.915 0 011.256.52l.3.844a1.584 1.584 0 002.984 0l.3-.844a.916.916 0 011.257-.52l.809.384a1.584 1.584 0 002.11-2.11l-.384-.809a.916.916 0 01.52-1.257l.844-.3a1.583 1.583 0 000-2.984zM8 11.19a3.287 3.287 0 01-2.96-1.962 3.209 3.209 0 011.733-4.187 3.245 3.245 0 014.187 1.733 3.208 3.208 0 01-1.733 4.187 3.06 3.06 0 01-1.227.23z">
                            </path>
                          </g>
                        </svg>
                        <span >Settings</span>
                      </Link>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
          )) : ""}
          </div>
            </div>


        <div className="plateform_section inactive_plateform">
              {appDetails.length ?<h2>Inactive Platforms</h2>:""}


          <div className="plateform_cards">
            {inactiveChannel.map(item => (
              <div key={item.id+12} className="plateform_card">
                <div className="card_top">
                  <div className="card_top_left">
                    <div className="card_top_inner">
                      <h3 color="black">{ item.channel_type ==2 ? "Google Android (FCM)":item.channel_type == 1 ?"Apple iOS (FCM)":"web"}</h3>
                      <div className="active_inactive">

                      </div>
                    </div>
                  </div>
                  <div className="card_top_right">
              {    item.channel_type ==2 ?   <svg viewBox="0 0 1274.37 718" fill="currentColor">
                      <path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84">
                      </path>
                    </svg>:item.channel_type == 1 ? <svg viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg>: <img src={web} width="55px"/>}
                  </div>
                </div>
                <div className="card_footer">
                  <div className="card_setting">
                  <Link to={`/apps/new/${appId}/setting/configure/${item.channel_type}`}>Activate</Link>
                    {/* <a href="#">Activate
                    </a> */}
                  </div>
                  <div>
                  </div>
                </div>
              </div> 
            ))}
            {/* {channelType != 1 ? <div className="plateform_card">
              <div className="card_top">
                <div className="card_top_left">
                  <div className="card_top_inner">
                    <h3 color="black">Apple iOS (FCM)</h3>
                    <div className="active_inactive">

                    </div>
                  </div>
                </div>
                <div className="card_top_right">
                  <svg viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg>
                </div>
              </div>
              <div className="card_footer">
                <div className="card_setting">
                  <a href="#">Activate
                  </a>
                </div>
                <div>
                </div>
              </div>
            </div> : ""}
            {channelType != 3 ? <div className="plateform_card">
              <div className="card_top">
                <div className="card_top_left">
                  <div className="card_top_inner">
                    <h3 color="black">Web</h3>
                    <div className="active_inactive">

                    </div>
                  </div>
                </div>
                <div className="card_top_right">
                 <img src={web} width="55px"/>
                </div>
              </div>
              <div className="card_footer">
                <div className="card_setting">
                  <a href="#">Activate
                  </a>
                </div>
                <div>
                </div>
              </div>
            </div> : ""} */}
          </div>
        </div>
      </div>:<div style={{display: "flex",justifyContent: "center",height:"45px"}}>
                        <img src={loader} alt="" />
                </div>}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: '#fff',
  },
  fileInputWrapper: {
    margin: '20px 0',
    position: 'relative',
  },
  fileInput: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
  },
  label: {
    display: 'inline-block',
    padding: '10px 20px',
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
    cursor: 'pointer',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#3b82f6',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  goBackButton: {
    padding: '10px 20px',
    backgroundColor: '#e0e0e0',
    color: '#333',
    border: 'none',
    borderRadius: '4px',
    marginLeft: '10px',
    cursor: 'pointer',
  },
};

export default Platforms;
