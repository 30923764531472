import React, { useEffect, useState, useRef } from 'react';
import { Utils } from '../index';
import { Link, useParams } from 'react-router-dom';
import { Button, Modal, Table } from 'react-bootstrap';
import { RxCross2 } from "react-icons/rx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import SkeletonLoader from "./SkeletonLoader";

const Subscriptions = () => {

    const { appId } = useParams()
    const [appUsers, setAppUsers] = useState([])
    const [isOpen, setIsOpen] = useState(null);
    const dropdownRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [viewHeight, setViewHeight] = useState("38%")
    const [noDataFound, setNoDataFound] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const data = [
        { id: 1, name: 'Row 1' },
        { id: 2, name: 'Row 2' },
        { id: 3, name: 'Row 3' },
    ];

    const handleOpenModal = (id) => {
        setSelectedRowId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setInputValue('');
    };

    const handleSubmit = () => {
        console.log('Row ID:', selectedRowId);
        console.log('Input Value:', inputValue);
        // update/app/users

        if (inputValue.length) {

            fetch(Utils.baseurl + 'update/app/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: selectedRowId, name: inputValue, isTest: 1 }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        // handleCallRenderList()
                        handleCloseModal();
                    }
                })
                .catch((error) => console.error(error));
        }

        handleCloseModal();
    };


    function getAppUser() {

        fetch(Utils.baseurl + 'get/app/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: appId }),
        })
            .then((response) => response.json())
            .then(async (result) => {

                if (result.status) {
                    /*
                     {
                        id: 2,
                        device_name: 'Sony Xperia 1',
                        lat: '36.23336447575133',
                        long: '43.95521303638816',
                        android_version: '10.0',
                        mobile: '9644025848',
                        name: 'Navneet',
                        device_token: 
                        'd4mb4OyyT5WzyfwG95tVNI:APA91bHjOwcSlq6l5Xnv1yebAEqHBiZ0f0vcuNvTlOzQTvlfkfFa9qPPamR2S3faecIOxXTOZeFyzBkx5MXvPHn_9JxZm-jdAFZxUlMzxzDWS0u88fI6yHQUmEizMToqiwD8Xdx9-bdB',
                        applicationId: 124,
                        isTest: 1,
                        createdAt: '2024-10-16T12:44:58.000Z',
                        updatedAt: '2024-10-24T06:28:44.000Z'
                    },
                    */
                    setIsLoading(false)
                    console.log(result.data);
                    setAppUsers(result.data.appUsersData)
                    if (!result.data.appUsersData.length) {
                        setNoDataFound(true)
                    } else {
                        setNoDataFound(false)
                    }

                }

            })
            .catch((error) => console.error(error));
    }


    useEffect(() => {
        // get/app/users
        getAppUser()
    }, []);




    console.log(appUsers);

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(null); // Close dropdown if click is outside

            }
        };

        // Attach the event listener when dropdown is open
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener when component unmounts or dropdown closes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);



    function handleAction(type, id) {

        if (type == "Delete") {
            setIsOpen(false)
            // /delete/application

            fetch(Utils.baseurl + 'delete/app/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        getAppUser()

                    }
                })
                .catch((error) => console.error(error));
        }

        if (type == "addtest") {
            setIsOpen(false)
            // navigate(`/apps/${id}`)
        }

    }


    function handleOpenDropdown(id, index) {
        setIsOpen(id)
        console.log(index);
        if (index) {
            setViewHeight(viewHeight + index * 10)
        }
    }
    console.log("viewHeight ", viewHeight);
    console.log(window.innerHeight)

let loaderData =  [
    {
      id: 1,
      name: 'test',
    },
    {
        id: 1,
        name: 'test',
      },
      {
        id: 1,
        name: 'test',
      },
      {
        id: 1,
        name: 'test',
      },
      {
        id: 1,
        name: 'test',
      },
      {
        id: 1,
        name: 'test',
      },
      {
        id: 1,
        name: 'test',
      },
      {
        id: 1,
        name: 'test',
      }
  ]

    return (
        <div className="dashboard_right">
            <div className="dashboard_inner">
                <div className="dashboard_right_top">
                    <div className="dashboard_heading">
                        <h1>Subscription Records</h1>
                    </div>
                    <div className="add_app">
                        <Link href={`/apps/${appId}/subscriptions`}>
                            <svg viewBox="0 0 16 16" fill="currentColor">
                                <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                                </path>
                            </svg>
                            <span>Add Subscription</span>
                        </Link>
                    </div>
                </div>
                <div className="dashboard_app_table">

                    <div className="dashboard_table">
                        <div className="table_inner">
                            <table className="table_outer">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Device Name</th>
                                        <th>Android Version</th>
                                        <th style={{ textAlign: "center" }}>Device Id</th>
                                        <th style={{ textAlign: "center" }}>Latitude</th>
                                        <th style={{ textAlign: "center" }}>Longitude</th>  
                                        <th style={{ textAlign: "center" }}>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appUsers.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" style={{ textAlign: "center" }}>
                                                {
                                                    isLoading ?
                                                    <SkeletonLoader columns={loaderData} rows={2} /> 
                                                    // <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> 
                                                        :<p  >No data found</p>
                                                }
                                            </td>
                                        </tr>
                                    ) : appUsers.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <button type="button" onClick={() => handleOpenDropdown(item.id, index)}>
                                                        <svg viewBox="0 0 16 16" fill="currentColor">
                                                            <path d="M8 5.25A1.625 1.625 0 108 2a1.625 1.625 0 000 3.25zM8 9.625a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM9.625 12.375a1.625 1.625 0 11-3.25 0 1.625 1.625 0 013.25 0z" fill="currentColor">
                                                            </path>
                                                        </svg>
                                                    </button>
                                                    {isOpen == item.id ? <div ref={dropdownRef} style={{ position: "absolute", left: "3%", top: viewHeight }}>
                                                        <div className='dropdown-menu2' >
                                                            <button onClick={() => handleOpenModal(item.id)}>
                                                                <svg viewBox="0 0 16 16" fill="currentColor">
                                                                    <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                                                                    </path>
                                                                </svg> add test subscription
                                                            </button>
                                                            <button className="delete-class"
                                                                style={{ color: "red" }}
                                                                onClick={() => handleAction('Delete', item.id)}
                                                            >
                                                                <RxCross2 className="dashboardsvg" color="red" />  Delete
                                                            </button>
                                                        </div>
                                                    </div> : ""}
                                                </td>
                                                <td>
                                                    <a href="#">{item.device_model}</a>
                                                </td>
                                                <td style={{ textAlign: "center" }}><span>{item.device_os}</span> </td>
                                                <td style={{ textAlign: "center" }}><span>{item.token.slice(0, 30)}...</span></td>
                                                <td style={{ textAlign: "center" }}>{item.lat}</td>
                                                <td style={{ textAlign: "center" }}>{item.long}</td>
                                                <td style={{ textAlign: "center" }}><span>0</span> </td>

                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            {/* Modal */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Test User</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <span style={{ fontSize: "15px" }}> Enter a name for this test user :</span>

                                    <input
                                        style={{ marginTop: "6px" }}
                                        type="text"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        placeholder=""
                                        className="form-control"
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <button style={{ background: "none", border: "0", marginRight: "12px", color: "rgb(67, 70, 206)" }} onClick={handleCloseModal}>
                                        Cancel
                                    </button>
                                    <div className="add_app" onClick={handleSubmit}>
                                        <Link to={`/apps/${appId}/subscriptions`}>
                                            <span>Add</span>
                                        </Link>
                                    </div>
                                    {/* <Button variant="primary" onClick={handleSubmit}>
                                        Submit
                                    </Button> */}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subscriptions;
