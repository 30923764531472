import React, { useEffect, useState, useRef } from "react";
import { Utils } from '../index';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import '../App.css'
import TbDropdown from "./Dropdown";
import Selectize from './Selectize';
import RenameApplication from "./RenameApplication";
import { Button, Modal, Table } from 'react-bootstrap';
import { BsPencil } from "react-icons/bs";
import { RiDashboard3Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import SkeletonLoader from "./SkeletonLoader";
const Applications = ({ changeSidebar }) => {
    const [plan, setPlan] = useState("")
    const [appList, setAppList] = useState([])
    const [isOpen, setIsOpen] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    // const toggleDropdown = () => {
    //     setIsOpen(!isOpen);
    // };

    const [showModal, setShowModal] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const data = [
        { id: 1, name: 'Row 1' },
        { id: 2, name: 'Row 2' },
        { id: 3, name: 'Row 3' },
    ];

    const handleOpenModal = (id, name) => {
        setInputValue(name)
        setSelectedRowId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setInputValue('');
    };

    const handleSubmit = () => {
        console.log('Row ID:', selectedRowId);
        console.log('Input Value:', inputValue);


        if (inputValue.length) {

            fetch(Utils.baseurl + 'update/application', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ application_name: inputValue, id: selectedRowId }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        handleCallRenderList()
                        handleCloseModal();
                    }
                })
                .catch((error) => console.error(error));
        }


    };







    async function handleCallRenderList() {
        const user = JSON.parse(localStorage.getItem('userData'));

        if (user) {

            fetch(Utils.baseurl + 'user/getbyid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {
                        console.log(result.data.plans);
                        if (result.data) {
                            if (result.data.plans[0].plan_type == 1) {
                                setPlan("free")
                            }
                            if (result.data.plans[0].plan_type == 2) {
                                setPlan("Growth")
                            }
                            if (result.data.plans[0].plan_type == 3) {
                                setPlan("Professional")
                            }
                            if (result.data.plans[0].plan_type == 4) {
                                setPlan("Enterprise")
                            }
                        }

                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));




            // Fetch the application list only once when userData is set
            fetch(Utils.baseurl + 'getall/application', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.status) {
                        console.log(result.data);
                        setIsLoading(false)
                        setAppList(result.data);
                    } else if (result.errors) {
                        // Handle server-side validation errors (optional)
                    }
                })
                .catch((error) => console.error(error));
        }
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('userData'));

        if (user) {

            fetch(Utils.baseurl + 'user/getbyid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {

                        if (result.data) {
                            if (result.data.plans[0].plan_type == 1) {
                                setPlan("free")
                            }
                            if (result.data.plans[0].plan_type == 2) {
                                setPlan("Growth")
                            }
                            if (result.data.plans[0].plan_type == 3) {
                                setPlan("Professional")
                            }
                            if (result.data.plans[0].plan_type == 4) {
                                setPlan("Enterprise")
                            }
                        }

                    } else {
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));




            // Fetch the application list only once when userData is set
            fetch(Utils.baseurl + 'getall/application', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id }),
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.status) {
                        console.log(result.data);

                        setAppList(result.data);
                    } else if (result.errors) {
                        // Handle server-side validation errors (optional)
                    }
                })
                .catch((error) => console.error(error));
        }
    }, []);

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(null); // Close dropdown if click is outside

            }
        };

        // Attach the event listener when dropdown is open
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener when component unmounts or dropdown closes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    function handleAction(type, id) {

        if (type == "Delete") {
            setIsOpen(false)
            // /delete/application

            fetch(Utils.baseurl + 'delete/application', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id }),
            })
                .then((response) => response.json())
                .then(async (result) => {
                    if (result.status) {

                        handleCallRenderList()
                        alert(result.message)
                    }
                })
                .catch((error) => console.error(error));
        }

        if (type == "Open Dashboard") {
            navigate(`/apps/${id}`)
            changeSidebar('dashboardSidebar')
        }

    }


    useEffect(() => {
        changeSidebar('defaultSidebar', 0)
        localStorage.setItem("thirdSidebar", 0)
    }, []);

    const toggleModal = () => {

        setModalShow(!modalShow);
    };



    function handleSelectedOption(params) {
        handleAction('Open Dashboard', params)

    }
    console.log(appList);

    let loaderData =  [
        {
          id: 1,
          name: 'test',
        },
        {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          },
          {
            id: 1,
            name: 'test',
          }
      ]


    return (
        <div className="dashboard_right">
            <div className="dashboard_inner">
                <div className="dashboard_right_top">
                    <div className="dashboard_heading">
                        <h1>All Apps</h1>
                    </div>
                    <div className="add_app">

                        <Link to="/apps/new">


                            <svg viewBox="0 0 16 16" fill="currentColor">
                                <path d="M.333 8A7.667 7.667 0 108 .333 7.675 7.675 0 00.333 8zm3.334-.333A.667.667 0 014.333 7h2.5A.167.167 0 007 6.833v-2.5a.667.667 0 01.667-.666h.666A.667.667 0 019 4.333v2.5A.167.167 0 009.167 7h2.5a.667.667 0 01.666.667v.666a.667.667 0 01-.666.667h-2.5A.167.167 0 009 9.167v2.5a.667.667 0 01-.667.666h-.666A.667.667 0 017 11.667v-2.5A.167.167 0 006.833 9h-2.5a.667.667 0 01-.666-.667v-.666z" fill="currentColor">
                                </path>
                            </svg>
                            <span>New App/Website</span>

                        </Link>


                    </div>
                </div>
                <div className="dashboard_app_table">
                    <div className="search_app">
                        <div>
                            <div className="search_app_dropdown">
                                {/* <select id="select-state" placeholder="Search apps">
                                    <option value="">Select a state...</option>
                                    {appList.map(item => (
                                        <option key={item.id} value={item.application_name}>{item.application_name}</option>
                                    ))}
                                </select> */}
                                <Selectize appList={appList} handleSelectedOption={handleSelectedOption} />
                            </div>
                        </div>
                    </div>
                    <div className="dashboard_table">
                        <div className="table_inner">
                            <table className="table_outer">
                                <thead>
                                    <tr>
                                        <th >Name</th>
                                        <th >Organization Name</th>
                                        <th style={{ textAlign: "center" }}>Plan</th>
                                        <th style={{ textAlign: "center" }} >Mobile (MAU)</th>
                                        <th style={{ textAlign: "center" }} >Web Push Subscribers</th>
                                        <th style={{ textAlign: "center" }} >Total Records</th>
                                        <th style={{ textAlign: "center" }} >Total Subscribed</th>
                                        <th>Platforms</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appList.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" style={{ textAlign: "center" }}>
                                                {
                                                    isLoading ?
                                                        // <FontAwesomeIcon icon={faSpinner} style={{ marginTop: "10px" }} spin size="2x" /> 
                                                        <SkeletonLoader columns={loaderData} rows={2} />
                                                        :<p style={{ fontSize: 20, fontWeight: 500 }} >No data found</p>
                                                }
                                            </td>
                                        </tr>
                                    ) : appList.map(item => (
                                        <tr key={item.id}>
                                            <td style={{ fontWeight: "bold", cursor: "pointer" }}
                                                onMouseEnter={(e) => e.target.style.cursor = "pointer"}
                                                onMouseLeave={(e) => e.target.style.cursor = "default"} onClick={() => handleAction('Open Dashboard', item.app_id)}>
                                                {item.application_name}
                                            </td>
                                            <td style={{ fontWeight: "bold", cursor: "pointer" }}
                                                onMouseEnter={(e) => e.target.style.cursor = "pointer"}
                                                onMouseLeave={(e) => e.target.style.cursor = "default"} >
                                                {item.organization.oganization_name}
                                            </td>
                                            <td style={{ textAlign: "center" }} className="plan"><span>{plan}</span></td>
                                            <td style={{ textAlign: "center" }} >0</td>
                                            <td style={{ textAlign: "center" }} >0</td>
                                            <td style={{ textAlign: "center" }}><span>0</span> &nbsp;(+0%)</td>
                                            <td style={{ textAlign: "center" }}><span>0</span></td>
                                            <td style={{ textAlign: "left" }}>
                                                <>
                                                    {

                                                        item.applicationDetails.length ? item.applicationDetails.map(application => (
                                                            application.json_name && application.channel_type == 2 ?
                                                                <svg key={application.id} style={{ height: "16px", width: "20%" }} viewBox="0 0 1274.37 718" fill="currentColor" className="sc-jSMfEi hKsxtk"><path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84"></path></svg>
                                                                : application.json_name && application.channel_type == 1 ?
                                                                    <svg key={application.id} style={{ height: "20px", width: "40%" }} viewBox="0 0 48 48" fill="currentColor"><g fill="#000"><path d="M33.051.057s-3.56.087-6.825 3.351c-3.266 3.265-2.715 7.663-2.715 7.663s3.232.592 6.682-2.858S33.05.057 33.05.057zM23.885 13.856c-2.407-.017-3.886-2.095-7.984-2.095-3.688 0-7.385 2.38-8.957 4.682-1.565 2.292-2.92 4.805-2.92 9.906 0 5.1 2.457 13.872 8.495 19.81 1.034 1.018 2.403 1.782 3.966 1.83 2.809.084 4.534-2.018 8.082-2.018 3.548 0 4.616 2.017 7.819 2.017 1.457 0 3.117-.663 4.73-2.38 2.07-2.2 4.64-6.393 5.947-10.414 0 0-6.551-2.53-6.473-9.824.066-6.045 5.2-9.018 5.2-9.018s-2.999-4.78-9.372-4.78c-4.401.033-6.373 2.299-8.533 2.284z"></path></g></svg>
                                                                    : "Continue Setup"
                                                        )) : ""
                                                    }
                                                </>

                                            </td>
                                            <td >
                                                <button type="button" className="tabButton" onClick={() => setIsOpen(item.id)}>
                                                    <svg viewBox="-4 0 16 16" fill="currentColor">
                                                        <path d="M8 5.25A1.625 1.625 0 108 2a1.625 1.625 0 000 3.25zM8 9.625a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM9.625 12.375a1.625 1.625 0 11-3.25 0 1.625 1.625 0 013.25 0z" fill="currentColor">
                                                        </path>
                                                    </svg>
                                                </button>
                                                {isOpen == item.id ? <div ref={dropdownRef} style={{ position: "absolute", right: "14%" }}>
                                                    <div className='dropdown-menu1' >
                                                        <button onClick={() => handleAction('Open Dashboard', item.app_id)}>
                                                            <RiDashboard3Fill className="dashboardsvg" /> Open Dashboard
                                                        </button>
                                                        <button onClick={() => handleOpenModal(item.id, item.application_name)} >
                                                            <BsPencil className="dashboardsvg" /> Rename
                                                        </button>
                                                        <button className="delete-class"
                                                            style={{ color: "red" }}
                                                            onClick={() => handleAction('Delete', item.id)}
                                                        >
                                                            <RxCross2 className="dashboardsvg" color="red" />  Delete
                                                        </button>
                                                    </div>
                                                </div> : ""}
                                            </td>
                                        </tr>
                                    ))
                                    // : (
                                    // <tr>
                                    //     <td colSpan="9" style={{ textAlign: "center" }}></td>
                                    // </tr>
                                    //   )
                                      }
                                    {/* <tr>
                                    <td>
                                        <a href="#">kashfiya</a>
                                    </td>
                                    <td className="plan"><span>Free</span></td>
                                    <td className="text-end">0</td>
                                    <td className="text-end">0</td>
                                    <td className="text-end"><span>0</span> &nbsp;(+0%)</td>
                                    <td className="text-end"><span>0</span></td>
                                    <td>
                                        <svg viewBox="0 0 1274.37 718"  fill="currentColor" className="sc-jSMfEi kGzldm sc-dzVsgF MkLrn platform-icon">
                                            <path d="m930.77 536.42a53.07 53.07 0 1 1 53.06-53.08 53.14 53.14 0 0 1 -53.06 53.08m-586.54 0a53.07 53.07 0 1 1 53.06-53.08 53.13 53.13 0 0 1 -53.06 53.08m605.57-319.65 106.05-183.68a22.06 22.06 0 1 0 -38.21-22.09l-107.39 186c-82.12-37.48-174.35-58.35-272.76-58.35s-190.63 20.9-272.75 58.35l-107.38-186a22.06 22.06 0 1 0 -38.22 22.06l106.06 183.71c-182.11 99.05-306.67 283.41-324.89 501.23h1274.38c-18.24-217.82-142.79-402.18-324.89-501.23" fill="#3ddc84">
                                            </path>
                                        </svg>
                                    </td>
                                    <td>
                                        <button type="button">
                                            <svg viewBox="0 0 16 16"  fill="currentColor">
                                                <path d="M8 5.25A1.625 1.625 0 108 2a1.625 1.625 0 000 3.25zM8 9.625a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM9.625 12.375a1.625 1.625 0 11-3.25 0 1.625 1.625 0 013.25 0z" fill="currentColor">
                                                </path>
                                            </svg>
                                        </button>
                                    </td>
                                </tr> */}
                                </tbody>
                            </table>
                            {/* Modal */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title> <b> Rename App</b> </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ margin: "5px", color: "#44464d" }} > New App Name</div>
                                    <input
                                        type="text"
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        className="form-control"
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <button style={{ background: "none", border: "0", marginRight: "12px", color: "rgb(67, 70, 206)" }} onClick={handleCloseModal}>
                                        Cancel
                                    </button>
                                    <div className="add_app" onClick={handleSubmit}>
                                        <Link to="/apps">
                                            <span>Rename</span>
                                        </Link>
                                    </div>
                                    {/* <Button variant="primary" onClick={handleSubmit}>
                                       Rename
                                    </Button> */}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Applications;
